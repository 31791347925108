@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');

* {
  font-family: 'Raleway, sans-serif';
}

.dump {
  color: rgb(3, 150, 248);
}

.navbar {
  background-color: white;
  height: 75px;
}

.navbar ul a {
  color: rgb(3, 3, 102);
}

.navbar ul a:hover {
  color: #ff0062;
}

.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  right: 100%;
  margin-top: -6px;
}

.dropdown-menu div li a {
  font-size: 20px;
}

/* rotate caret on hover */
.dropdown-menu>li>a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

.nav-item ul li a {
  color: black;
}

.upper-come-nav-part {
  font-size: 20px;
}


.bi {
  width: 40px;
  font-size: 25px;
  margin-right: 5px;
  background-color: grey;
  /* opacity: 0.6; */
}

.bi:hover {
  cursor: pointer;
}

#socialMedia a {
  color: #0a0b0b;
  width: 40px;
  font-size: 25px;
  margin-right: 5px;
  background-color: grey;
}

.bi-telephone:hover {
  color: #08f37d;
}

.bi-linkedin:hover {
  color: #0a66c2;
}

.bi-facebook:hover {
  color: rgb(11, 70, 233);
}

.bi-twitter:hover {
  color: rgb(10, 177, 243);
}

.bi-whatsapp:hover {
  color: rgb(12, 247, 12);
}

.bi-envelope-at:hover {
  color: rgb(201, 5, 5);
}

.bi-telephone {
  position: relative;
}

.bi-telephone .tooltiptext {
  width: 110px;
  font-size: 12px;
  background-color: black;
  border: 1px solid white;
  text-align: start;
  visibility: hidden;
  color: #fff;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.bi-telephone:hover .tooltiptext {
  visibility: visible;
}

.bi-envelope-at {
  position: relative;
}

.bi-envelope-at .tooltiptext {
  font-size: 12px;
  background-color: black;
  border: 1px solid white;
  text-align: start;
  visibility: hidden;
  color: #fff;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.bi-envelope-at:hover .tooltiptext {
  visibility: visible;
}

.bi-linkedin {
  position: relative;
}

.bi-linkedin .tooltiptext {
  font-size: 12px;
  background-color: black;
  border: 1px solid white;
  text-align: start;
  visibility: hidden;
  color: #fff;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.bi-linkedin:hover .tooltiptext {
  visibility: visible;
}

.bi-facebook {
  position: relative;
}

.bi-facebook .tooltiptext {
  font-size: 12px;
  background-color: black;
  border: 1px solid white;
  text-align: start;
  visibility: hidden;
  color: #fff;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.bi-facebook:hover .tooltiptext {
  visibility: visible;
}

.bi-twitter {
  position: relative;
}

.bi-twitter .tooltiptext {
  font-size: 12px;
  background-color: black;
  border: 1px solid white;
  text-align: start;
  visibility: hidden;
  color: #fff;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.bi-twitter:hover .tooltiptext {
  visibility: visible;
}

.bi-whatsapp {
  position: relative;
}

.bi-whatsapp .tooltiptext {
  width: 110px;
  font-size: 12px;
  background-color: black;
  border: 1px solid white;
  text-align: start;
  visibility: hidden;
  color: #fff;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.bi-whatsapp:hover .tooltiptext {
  visibility: visible;
}


.btn1 {
  padding: 20px;
  border: 0px;
  border-radius: 10px;
  background-color: #d41b97;
  color: #fff;
  font-size: 18px;
}


#socialMedia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 500px;
  position: fixed;
}

#makeanenquiry a {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: 500px;
  position: fixed;
  right: 0;
  text-decoration: none;
  font-size: 25px;
}

#eqform {
  height: 600px;
  background-image: url('/public/images/enquiry.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

#eqnform {
  margin-top: 75px;
  margin-left: 400px;
  padding: 50px;
}

#footerblock {
  display: flex;
  flex-direction: column;
  background-image: url('/public/images/footerbg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.d-block {
  height: 700px;
  opacity: 0.8;
}

#carouselExampleInterval {
  width: auto;
  /* height: 300px; */
  margin-left: 40px;
  margin-top: -75px;
  /* padding: 50px; */
}

.carousel-item {
  top: 90px;
  height: 650px;
}

.carousel-caption {
  width: auto;
  height: 200px;
}

.carousel-caption p {
  font-size: 25px;
  font-weight: bold;
  color: white;
  margin-left: 300px;
}

#content1 {
  width: auto;
  height: auto;
  background-image: linear-gradient(to right, #2b2b4d, #364a68, #6a8496, #adc0c6, #fafdfd);
}

#ourservice {
  height: auto;
  background-image: url('/public/images/service2.jpg');
  background-size: cover;
  background-repeat: no-repeat;

}

#corporateblock {
  height: auto;
  background-image: url('/public/images/corporatebg1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

#industries {
  height: auto;
  background-image: url('/public/images/industriesbg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

#solutions {
  height: auto;
  background-image: url('/public/images/solutionbg1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

#navbarSupportedContent {
  margin-left: 100px;
}

#marque {
  background-image: linear-gradient(to right top, #0a0b0b, #3e403f, #767978, #b4b8b4, #f8fbf5);
}


/* First section */
section .cards1 {
  display: flex;
}

section .cards1 .text-cards1 {
  width: 300px;
  height: 300px;
  background-color: rgb(7, 185, 230);
  margin: 20px;
  padding: 25px;
}

section .cards1 .text-cards1 h3 {
  font-size: 20px;
  text-align: start;
  margin-bottom: 10px;
  font-weight: bolder;
}

.reveal1 {
  position: relative;
  transform: translateY(25px);
  opacity: 0;
  transition: all 2s ease;
}

.reveal1.active {
  transform: translateY(0);
  opacity: 1;
}

/* Second section */
section .cards2 {
  display: flex;
}

section .cards2 .text-cards2 {
  width: 300px;
  height: 300px;
  background-color: rgb(132, 139, 240);
  margin: 20px;
  padding: 25px;
}

section .cards2 .text-cards2 h3 {
  font-size: 20px;
  text-align: start;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bolder;
}

.reveal2 {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: all 2s ease;
}

.reveal2.active {
  transform: translateY(0);
  opacity: 1;
}

/* Third section */
section .cards3 {
  display: flex;
}

section .cards3 .text-cards3 {
  width: 300px;
  height: 300px;
  background-color: rgb(252, 4, 157);
  margin: 20px;
  padding: 25px;
}

section .cards3 .text-cards3 h3 {
  font-size: 20px;
  text-align: start;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bolder;
}

.reveal3 {
  position: relative;
  transform: translateY(75px);
  opacity: 0;
  transition: all 2s ease;
}

.reveal3.active {
  transform: translateY(0);
  opacity: 1;
}

/* Fourth section */
section .cards4 {
  display: flex;
}

section .cards4 .text-cards4 {
  width: 300px;
  height: 300px;
  background-color: rgb(132, 139, 240);
  margin: 20px;
  padding: 25px;
}

section .cards4 .text-cards4 h3 {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bolder;
}

.reveal4 {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: all 2s ease;
}

.reveal4.active {
  transform: translateY(0);
  opacity: 1;
}

/* Fifth section */
section .cards5 {
  display: flex;
}

section .cards5 .text-cards5 {
  width: 300px;
  height: 300px;
  background-color: rgb(132, 139, 240);
  margin: 20px;
  padding: 25px;
}

section .cards5 .text-cards5 h3 {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bolder;
}

.reveal5 {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: all 2s ease;
}

.reveal5.active {
  transform: translateY(0);
  opacity: 1;
}

.reveal6 {
  position: relative;
  transform: translateX(-100px);
  opacity: 0;
  transition: all 2s ease;
}

.reveal6.active {
  transform: translateX(0);
  opacity: 1;
}

/* Solutions */
#bigdata img {
  transition: 1.2s;
}

#bigdata img:hover {
  box-shadow: 3px 3px 10px rgb(3, 121, 231), -3px -3px 10px rgb(3, 121, 231);
}

.links ul li {
  list-style-type: none;
  text-align: start;
}

.links ul li a {
  text-decoration: none;
  color: white;
}

.links ul li a:hover {
  text-decoration: underline;
  color: rgb(8, 131, 247);
}


.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
}

.show {
  opacity: 1;
  transform: translateX(0);
}


.modal-body {
  background-image: url('/public/images/teambg3.avif');
}

#modal1 {
  padding: 0px;
  box-shadow: 5px 5px 15px black, -5px -5px 15px black;
  transition: all 1.4s;
}

#modal1:hover {
  box-shadow: 5px 5px 15px white, -5px -5px 15px white;
}


/* media queries */

/* For Desktops */
@media (min-width: 1281px) {
  /*(min-width: 1281px)*/

  /* Navbar */
  #navbar1 {
    display: block;
  }

  #navbar2 {
    display: none;
  }

  #navbarSupportedContent ul {
    margin-left: -50px;
  }

  /* Enquiry Form */
  #eqform {
    height: 600px;
    background-image: url('/public/images/enquiry.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  #eqnform {
    margin-top: 75px;
    margin-left: 400px;
    padding: 50px;
  }

  #eqnform form {
    width: 50%;
  }


  /* Footer */
  #footercontentblock1 {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  #footercontentblock2 {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  #footercontent1 {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
    text-align: start;
  }

  #footer1 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer1 h3 {
    font-size: 30px;
    margin-left: 35px;
  }

  #footer1 .links {
    font-size: 18px;
  }
  #footer1 .links ul li a {
    transition: all 1s;
  }
  #footer1 .links ul li a:hover {
    margin-left: 10px;
  }
  

  #divide1 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer2 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer2 h3 {
    font-size: 30px;
    margin-left: 35px;
  }

  #footer2 .links {
    font-size: 18px;
  }
  #footer2 .links ul li a {
    transition: all 1s;
  }
  #footer2 .links ul li a:hover {
    margin-left: 10px;
  }

  #divide2 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer3 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer3 h3 {
    font-size: 30px;
    margin-left: 30px;
  }

  #footer3 .links {
    font-size: 18px;
  }
  #footer3 .links ul li a {
    transition: all 1s;
  }
  #footer3 .links ul li a:hover {
    margin-left: 10px;
  }

  #divide3 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer4 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer4 h3 {
    font-size: 30px;
    margin-left: 30px;
  }

  #footer4 .links {
    font-size: 18px;
  }
  #footer4 .links ul li a {
    transition: all 1s;
  }
  #footer4 .links ul li a:hover {
    margin-left: 10px;
  }

  #divide4 {
    width: auto;
    height: 1px;
    border: 1px solid grey;
  }

  #footertext {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  #footertext p {
    margin-top: 10px;
    font-size: 15px;
  }

  #footertext span a {
    font-size: 20px;
    text-decoration: none;
    color: white;
  }

  #footertext span a:hover {
    color: rgb(8, 131, 247);
    text-decoration: underline;
  }

  /* Terms and conditions */
  #terms {
    margin: 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #terms h3 {
    color: white;
    font-size: 30px;
    font-weight: bolder;
  }

  #terms h2 {
    color: white;
    font-size: 35px;
    font-weight: bolder;
  }

  #terms p {
    font-size: 22px;
    color: white;
  }

  #terms ul li {
    font-size: 22px;
    color: white;
  }

  /* Privacy Policy */
  #privacy {
    margin: 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #privacy h2 {
    color: white;
    font-size: 35px;
    font-weight: bolder;
  }

  #privacy h3 {
    color: white;
    font-size: 30px;
    font-weight: bolder;
  }

  #privacy p {
    color: white;
    font-size: 22px;
  }

  #privacy ul li {
    color: white;
    font-size: 22px;
  }

  #indialocation {
    margin-left: 250px;
  }

  /* Home Page */
  /* NavBar */
  .navbar {
    width: 100%;
  }

  .navbar #navbarSupportedContent {
    width: auto;
  }

  .navbar #logo h5 {
    font-size: 25px;
    margin-left: 25px;
    font-weight: bold;
  }


  /* carousel */
  #carouselExampleInterval {
    width: auto;
    margin-left: 40px;
    margin-top: -75px;
    margin-right: 45px;
  }

  .carousel-item {
    top: 90px;
    height: 650px;
  }

  .carousel-caption {
    width: auto;
    height: 200px;
  }

  .carousel-caption p {
    font-size: 25px;
    font-weight: bold;
    color: white;
    margin-left: 300px;
  }

  #carousel1 .carousel-caption p {
    font-size: 40px;
    margin-top: -100px;
    margin-left: -200px;
  }

  #carousel2 .carousel-caption p {
    font-size: 40px;
    margin-top: -50px;
    margin-left: 550px;
  }

  #carousel3 .carousel-caption p {
    font-size: 40px;
    margin-top: -50px;
    margin-left: 300px;
  }

  /* About */
  #section1 h1 {
    width: auto;
    font-size: 40px;
  }

  #section1 p {
    width: auto;
    font-size: 22px;
  }

  #aboutusblock1 {
    display: flex;
    flex-direction: row;
  }

  #aboutuscontent1 {
    width: 50%;
    text-align: start;
  }

  #aboutusbtn1 {
    width: 150px;
    padding: 10px;
  }

  #aboutusimg1 {
    width: 50%;
  }

  #aboutusimg1 video {
    width: 100%;
    height: 350px;
  }

  #aboutusimg1 img {
    width: 500px;
    height: 400px;
  }

  /* Corporate */
  #section21 {
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #corporateblock .cards1 {
    display: flex;
  }

  #corporateblock .cards1 .text-cards1 {
    width: 300px;
    height: 300px;
    background-color: rgb(7, 230, 7);
    margin: 20px;
    padding: 25px;
  }

  #corporateblock .cards1 .text-cards1 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  #corporateblock .cards2 {
    display: flex;
  }

  #corporateblock .cards2 .text-cards2 {
    width: 300px;
    height: 300px;
    background-color: rgb(245, 10, 233);
    margin: 20px;
    padding: 25px;
  }

  #corporateblock .cards2 .text-cards2 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  #corporateblock .cards3 {
    display: flex;
  }

  #corporateblock .cards3 .text-cards3 {
    width: 300px;
    height: 300px;
    background-color: rgb(230, 178, 7);
    margin: 20px;
    padding: 25px;
  }

  #corporateblock .cards3 .text-cards3 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }


  #section2 h2 {
    width: auto;
    font-size: 40px;
  }

  #section2 p {
    width: auto;
    font-size: 30px;
  }

  #section2 #bigdata img {
    width: 500px;
    height: 350px;
  }

  #section3 #bigdata img {
    width: 500px;
    height: 350px;
  }

  #section3 h2 {
    width: auto;
    font-size: 40px;
  }

  #section3 p {
    width: auto;
    font-size: 30px;
  }

  #section4 h2 {
    width: auto;
    font-size: 40px;
  }

  #section4 p {
    width: auto;
    font-size: 30px;
  }

  #section4 #bigdata img {
    width: 300px;
    height: 300px;
  }

  #section5 #bigdata img {
    width: 80%;
    height: 350px;
  }

  #section5 h2 {
    width: 100%;
    font-size: 40px;
  }

  #section5 p {
    width: 100%;
    font-size: 30px;
  }

  #section6 h1 {
    width: 100%;
    font-size: 40px;
  }

  #section6 p {
    width: 100%;
    font-size: 25px;
  }


  /* Solutions */
  #section7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section8 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section9 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #solutions section .cards12 {
    display: flex;
  }

  #solutions section .cards12 .text-cards12 {
    width: 300px;
    height: 300px;
    background-color: rgb(5, 122, 255);
    margin: 20px;
    padding: 25px;
  }

  #solutions section .cards12 .text-cards12 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  #solutions section .cards13 {
    display: flex;
  }

  #solutions section .cards13 .text-cards13 {
    width: 300px;
    height: 300px;
    background-color: rgb(247, 151, 8);
    margin: 20px;
    padding: 25px;
  }

  #solutions section .cards13 .text-cards13 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Third section */
  #solutions section .cards14 {
    display: flex;
  }

  #solutions section .cards14 .text-cards14 {
    width: 300px;
    height: 300px;
    background-color: rgb(196, 11, 165);
    margin: 20px;
    padding: 20px;
  }

  #solutions section .cards14 .text-cards14 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Fourth section */
  #solutions section .cards15 {
    display: flex;
  }

  #solutions section .cards15 .text-cards15 {
    width: 300px;
    height: 300px;
    background-color: rgb(10, 238, 181);
    margin: 20px;
    padding: 25px;
  }

  #solutions section .cards15 .text-cards15 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Fifth section */
  #solutions section .cards16 {
    display: flex;
  }

  #solutions section .cards16 .text-cards16 {
    width: 300px;
    height: 300px;
    background-color: rgb(248, 244, 7);
    margin: 20px;
    padding: 25px;
  }

  #solutions section .cards16 .text-cards16 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }


  /* Our services */
  #section31 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section32 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section33 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards1 {
    display: flex;
  }

  section .cards1 .text-cards1 {
    width: 300px;
    height: 300px;
    background-color: rgb(7, 185, 230);
    margin: 20px;
    padding: 25px;
  }

  section .cards1 .text-cards1 h3 {
    font-size: 20px;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  section .cards2 {
    display: flex;
  }

  section .cards2 .text-cards2 {
    width: 300px;
    height: 300px;
    background-color: rgb(132, 139, 240);
    margin: 20px;
    padding: 25px;
  }

  section .cards2 .text-cards2 h3 {
    font-size: 20px;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Third section */
  section .cards3 {
    display: flex;
  }

  section .cards3 .text-cards3 {
    width: 300px;
    height: 300px;
    background-color: rgb(252, 4, 157);
    margin: 20px;
    padding: 20px;
  }

  section .cards3 .text-cards3 h3 {
    font-size: 20px;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Fourth section */
  section .cards4 {
    display: flex;
  }

  section .cards4 .text-cards4 {
    width: 300px;
    height: 300px;
    background-color: rgb(132, 139, 240);
    margin: 20px;
    padding: 25px;
  }

  section .cards4 .text-cards4 h3 {
    font-size: 20px;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Fifth section */
  section .cards5 {
    display: flex;
  }

  section .cards5 .text-cards5 {
    width: 300px;
    height: 300px;
    background-color: rgb(132, 139, 240);
    margin: 20px;
    padding: 25px;
  }

  section .cards5 .text-cards5 h3 {
    font-size: 20px;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Industries */
  #section41 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section42 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section43 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards6 {
    display: flex;
  }

  section .cards6 .text-cards6 {
    width: 300px;
    height: 300px;
    background-color: rgb(250, 7, 68);
    margin: 20px;
    padding: 25px;
  }

  section .cards6 .text-cards6 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  section .cards7 {
    display: flex;
  }

  section .cards7 .text-cards7 {
    width: 300px;
    height: 300px;
    background-color: rgb(46, 245, 7);
    margin: 20px;
    padding: 25px;
  }

  section .cards7 .text-cards7 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Third section */
  section .cards8 {
    display: flex;
  }

  section .cards8 .text-cards8 {
    width: 300px;
    height: 300px;
    background-color: rgb(173, 4, 252);
    margin: 20px;
    padding: 20px;
  }

  section .cards8 .text-cards8 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Fourth section */
  section .cards9 {
    display: flex;
  }

  section .cards9 .text-cards9 {
    width: 300px;
    height: 300px;
    background-color: rgb(248, 5, 187);
    margin: 20px;
    padding: 25px;
  }

  section .cards9 .text-cards9 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Fifth section */
  section .cards10 {
    display: flex;
  }

  section .cards10 .text-cards10 {
    width: 300px;
    height: 300px;
    background-color: rgb(238, 118, 5);
    margin: 20px;
    padding: 25px;
  }

  section .cards10 .text-cards10 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  /* Sixth section */
  section .cards11 {
    display: flex;
  }

  section .cards11 .text-cards11 {
    width: 300px;
    height: 300px;
    background-color: rgb(11, 193, 238);
    margin: 20px;
    padding: 25px;
  }

  section .cards11 .text-cards11 h3 {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }

  #section10 h1 {
    font-size: 40px;
  }

  #section11 #bigdata img {
    width: 80%;
    height: 350px;
  }

  #section11 h2 {
    width: 100%;
    font-size: 40px;
  }

  #section11 p {
    width: 100%;
    font-size: 30px;
  }

  #section12 #bigdata img {
    width: 80%;
    height: 350px;
  }

  #section12 h2 {
    width: 100%;
    font-size: 40px;
  }

  #section12 p {
    width: 100%;
    font-size: 30px;
  }

  #section13 h2 {
    width: auto;
    font-size: 40px;
  }

  #section13 p {
    width: auto;
    font-size: 30px;
  }

  /* Aboutus */
  #aboutusblock {
    display: flex;
    flex-direction: row;
  }

  #aboutuscontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #aboutusimage {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  #aboutusimage img {
    width: 100%;
    height: 600px;
  }

  #aboutuscontent p {
    font-size: 22px;
    width: 100%;
  }

  /* Vision & Mission */
  #visionandmission h1 {
    font-size: 40px;
  }

  #visionandmission h4 {
    font-size: 35px;
  }

  #visionandmission p {
    font-size: 24px;
  }

  #visionandmission img {
    width: 100%;
    height: 300px;
  }

  /* Core value */
  #corevalue h1 {
    font-size: 40px;
  }

  #corevalue h3 {
    font-size: 35px;
    text-align: start;
  }

  #corevalue ul li {
    font-size: 22px;
  }

  /* Social Responsibility */
  #socialresponsibilityblock {
    display: flex;
    flex-direction: row;
  }

  #socialresponsibilitycontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #socialresponsibilityimg {
    width: 40%;
    padding-left: 45px;
    padding-top: 50px;
  }

  #socialresponsibilityimg img {
    width: 100%;
    height: 500px;
  }

  #socialresponsibility h1 {
    font-size: 40px;
  }

  #socialresponsibility h2 {
    font-size: 35px;
  }

  #socialresponsibility p {
    font-size: 22px;
  }

  /* Location */
  #location h1 {
    font-size: 40px;
  }

  #location h2 {
    font-size: 35px;
  }

  #location h3 {
    font-size: 30px;
  }

  #location p {
    font-size: 25px;
  }

  #locationbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #locationbtn #btn1 {
    width: 150px;
    font-size: 25px;
    margin-right: 25px;
  }

  #locationbtn #btn2 {
    width: 150px;
    font-size: 25px;
  }

  #locationblock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #locationblock #indialocation img {
    height: 300px;
  }

  #locationblock #vizaglocation img {
    height: 300px;
  }

  #malaysialocation img {
    height: 300px;
  }

  /* Teams */
  #teams {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  #teamsblock1 {
    margin-right: 50px;
  }

  #teamsblock1 .card {
    width: 300px;
    height: 350px;
    border: none;
  }

  #teamsblock1 .card img {
    width: 100%;
    height: 250px;
  }

  #teamsblock1 .card-body span {
    font-size: 20px;
    font-weight: bold;
  }

  #teamsblock1 .card-body p {
    font-size: 20px;
  }

  #teamsblock2 .card {
    width: 300px;
    height: 350px;
    border: none;
  }

  #teamsblock2 .card img {
    width: 100%;
    height: 250px;
  }

  #teamsblock2 .card-body span {
    font-size: 20px;
    font-weight: bold;
  }

  #teamsblock2 .card-body p {
    font-size: 20px;
  }

  #teamsblock1 .modal-content {
    width: 1200px;
    height: 550px;
    left: -350px;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock1 .modal-body {
    width: 40%;
  }

  #teamsblock1 .modal-body img {
    width: 350px;
    height: 350px;
    margin-top: 75px;
  }

  #teamsblock1 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    overflow: scroll;
  }

  #teamsblock1 .modal-title {
    margin-right: 150px;
  }

  #teamsblock1 .modal-text {
    font-size: 22px;
  }

  #teamsblock1 .modal-header .text {
    font-size: 18px;
    text-align: justify;
  }

  #teamsblock2 .modal-content {
    width: 1200px;
    height: 550px;
    left: -350px;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock2 .modal-body {
    width: 40%;
  }

  #teamsblock2 .modal-body img {
    width: 350px;
    height: 350px;
    margin-top: 75px;
  }

  #teamsblock2 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    overflow: scroll;
  }

  #teamsblock2 .modal-title {
    margin-right: 240px;
  }

  #teamsblock2 .modal-text {
    font-size: 22px;
  }

  #teamsblock2 .modal-header .text {
    font-size: 18px;
    text-align: justify;
  }

  /* Industries */
  /* Financial Services */
  #financialservice h1 {
    font-size: 40px;
  }

  #financialservice h4 {
    font-size: 35px;
  }

  #financialservice p {
    font-size: 22px;
  }

  #financialservice img {
    width: 100%;
    height: 500px;
  }

  #financialserviceblock {
    display: flex;
    flex-direction: row;
  }

  #financialservicecontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #financialserviceimg {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  /* Government */
  #government h1 {
    font-size: 40px;
  }

  #government h3 {
    font-size: 35px;
  }

  #government p {
    font-size: 22px;
  }

  #government ul li {
    font-size: 22px;
  }

  /* Manufacturing */
  #manufacturing h1 {
    font-size: 40px;
  }

  #manufacturing p {
    font-size: 22px;
  }

  #manufacturing ul li {
    font-size: 22px;
  }

  /* Retail */
  #retail h1 {
    font-size: 40px;
  }

  #retail img {
    width: 100%;
    height: 500px;
  }

  #retail p {
    font-size: 22px;
  }

  #retail ul li {
    font-size: 22px;
  }

  #retail #img {
    width: 40%;
    padding-left: 50px;
    padding-right: 50px;
  }

  #retail #retailcontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #retailblock {
    display: flex;
    flex-direction: row;
  }

  /* Telecommunication */
  #telecommunication h1 {
    font-size: 40px;
  }

  #telecommunication h4 {
    font-size: 35px;
  }

  #telecommunication p {
    font-size: 22px;
  }

  #telecommunication ul li {
    font-size: 22px;
  }

  #telecommunication img {
    width: 100%;
    height: 500px;
  }

  #telecommunicationblock {
    display: flex;
    flex-direction: row;
  }

  #telecommunicationcontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #telecommunicationimg {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  /* Utilities */
  #utilities h1 {
    font-size: 40px;
  }

  #utilities h3 {
    font-size: 35px;
  }

  #utilities p {
    font-size: 22px;
  }

  #utilities ul li {
    font-size: 22px;
  }

  /* Products */
  /* Log-Mode */
  #logmode h1 {
    font-size: 40px;
  }

  #logmode h4 {
    font-size: 35px;
  }

  #logmode h5 {
    font-size: 30px;
  }

  #logmode p {
    font-size: 22px;
  }

  #logmode img {
    width: 600px;
    height: 300px;
  }

  #logmodeblock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
  }

  /* Solutions */
  /* Application Middleware */
  #applicationmiddleware h1 {
    font-size: 40px;
  }

  #applicationmiddleware h3 {
    font-size: 35px;
  }

  #applicationmiddleware p {
    font-size: 22px;
  }

  #applicationmiddleware ul li {
    font-size: 22px;
  }

  /* Big Data */
  #bigdata h1 {
    font-size: 40px;
  }

  #bigdata h2 {
    font-size: 35px;
  }

  #bigdata p,
  b {
    font-size: 22px;
  }

  #bigdata ul li {
    font-size: 22px;
  }

  #bigdata img {
    width: 700px;
    height: 300px;
    margin-left: 300px;
  }

  /* Business Intelligence Solutions */
  #businessintelligence h1 {
    font-size: 40px;
  }

  #businessintelligence h3 {
    font-size: 35px;
  }

  #businessintelligence p,
  b {
    font-size: 22px;
  }

  #businessintelligence ul li {
    font-size: 22px;
  }

  #businessintelligenceblock1 {
    display: flex;
    flex-direction: row;
  }

  #businessintelligenceblock1 p {
    width: 50%;
    padding: 5px;
  }

  #businessintelligenceblock1 img {
    width: 50%;
    height: 450px;
  }

  #businessintelligenceblock2 {
    display: flex;
    flex-direction: row;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 img {
    width: 150%;
    height: 300px;
    margin-left: -150px;
  }

  /* Data Management */
  #datamanagement h1 {
    font-size: 40px;
  }

  #datamanagement h3 {
    font-size: 35px;
  }

  #datamanagement p,
  b {
    font-size: 22px;
  }

  #datamanagement ul li {
    font-size: 22px;
  }

  #datamanagement img {
    width: 60%;
    height: 300px;
  }

  #datamanagementblock {
    display: flex;
    flex-direction: row;
  }

  #datamanagementcontent {
    width: 50%;
  }

  #datamanagementimg {
    width: 50%;
  }


  /* Enterprise Data Warehouse */
  #enterprisedata h1 {
    font-size: 40px;
  }

  #enterprisedata h2 {
    font-size: 35px;
  }

  #enterprisedata p,
  b {
    font-size: 22px;
  }

  #enterprisedata ul li {
    font-size: 22px;
  }

  #enterprisedata img {
    width: 100%;
    height: 300px;
  }

  #enterprisedatablock {
    display: flex;
    flex-direction: row;
  }

  #enterprisedatacontent {
    width: 50%;
  }

  #enterprisedataimg {
    width: 50%;
  }


  /* Services */
  /* Business Intelligence Services */
  #businessIntelligence h1 {
    font-size: 40px;
  }

  #businessIntelligence h2 {
    font-size: 35px;
  }

  #businessIntelligence h4 {
    font-size: 30px;
  }

  #businessIntelligence p,
  b {
    font-size: 22px;
  }

  #businessIntelligence ul li {
    font-size: 22px;
  }

  /* Enterprise Mobility Service */
  #enterprisemobility h1 {
    font-size: 40px;
  }

  #enterprisemobility p {
    font-size: 22px;
  }

  #enterprisemobility ul li {
    font-size: 22px;
  }

  /* Mobile Apps */
  #mobileapps h1 {
    font-size: 40px;
  }

  #mobileapps p {
    font-size: 22px;
  }

  #mobileapps ul li {
    font-size: 22px;
  }

  /* Testing And QA Services */
  #testingandqa h1 {
    font-size: 40px;
  }

  #testingandqa p {
    font-size: 22px;
  }

  #testingandqa ul li {
    font-size: 22px;
  }

  /* Web Application */
  #webapplication h1 {
    font-size: 40px;
  }

  #webapplication p {
    font-size: 22px;
  }

  #webapplication ul li {
    font-size: 22px;
  }

  /* Enterprise Mobility */
  /* Business Process */
  #businessprocess h1 {
    font-size: 40px;
  }

  #businessprocess p {
    font-size: 22px;
  }

  /* B2B Mobile */
  #b2bmobile h1 {
    font-size: 40px;
  }

  #b2bmobile h2 {
    font-size: 35px;
  }

  #b2bmobile p {
    font-size: 22px;
  }

  #b2bmobile ul li {
    font-size: 22px;
  }

  /* Android App */
  #enterpriseandroid h1 {
    font-size: 40px;
  }

  #enterpriseandroid p {
    font-size: 22px;
  }

  #enterpriseandroid ul li {
    font-size: 22px;
  }

  /* Enterprise APP */
  #enterpriseapp h1 {
    font-size: 40px;
  }

  #enterpriseapp h3 {
    font-size: 35px;
  }

  #enterpriseapp p {
    font-size: 22px;
  }

  /* Enterprise IOS App */
  #enterpriseiosapp h1 {
    font-size: 40px;
  }

  #enterpriseiosapp h3 {
    font-size: 35px;
  }

  #enterpriseiosapp p {
    font-size: 22px;
  }

  /* Mobile Business */
  #mobilebusiness h1 {
    font-size: 40px;
  }

  #mobilebusiness p {
    font-size: 22px;
  }


  /* Mobile Apps */
  /* Android App */
  #androidapp h1 {
    font-size: 40px;
  }

  #androidapp p {
    font-size: 22px;
  }

  /* Blackberry App */
  #blackberryapp h1 {
    font-size: 40px;
  }

  #blackberryapp p {
    font-size: 22px;
  }

  /* Iphone App */
  #iphoneapp h1 {
    font-size: 40px;
  }

  #iphoneapp p {
    font-size: 22px;
  }

  /* Windows8 App */
  #windows8app h1 {
    font-size: 40px;
  }

  #windows8app p {
    font-size: 22px;
  }


  /* Testing & QA Services*/
  /* Automated Testing */
  #automatedtesting h1 {
    font-size: 40px;
  }

  #automatedtesting h3 {
    font-size: 35px;
  }

  #automatedtesting p {
    font-size: 22px;
  }

  #automatedtesting ul li {
    font-size: 22px;
  }

  /* Manual Testing */
  #manualtesting h1 {
    font-size: 40px;
  }

  #manualtesting h3 {
    font-size: 35px;
  }

  #manualtesting p {
    font-size: 22px;
  }

  #manualtesting ul li {
    font-size: 22px;
  }

  /* QTP */
  #qtp h1 {
    font-size: 40px;
  }

  #qtp h3 {
    font-size: 35px;
  }

  #qtp p {
    font-size: 22px;
  }

  #qtp ul li {
    font-size: 22px;
  }

  /* Selenium */
  #selenium h1 {
    font-size: 40px;
  }

  #selenium h3 {
    font-size: 35px;
  }

  #selenium p {
    font-size: 22px;
  }

  #selenium ul li {
    font-size: 22px;
  }

  /* Test Complete */
  #testcomplete h1 {
    font-size: 40px;
  }

  #testcomplete h3 {
    font-size: 35px;
  }

  #testcomplete p {
    font-size: 22px;
  }

  #testcomplete ul li {
    font-size: 22px;
  }


  /* Web Application */
  /* CRM Application */
  #cmrapplication h1 {
    font-size: 40px;
  }

  #cmrapplication p {
    font-size: 22px;
  }

  /* Content Management */
  #contentmanagement h1 {
    font-size: 40px;
  }

  #contentmanagement h3 {
    font-size: 35px;
  }

  #contentmanagement p {
    font-size: 22px;
  }

  #contentmanagement ul li {
    font-size: 22px;
  }

  /* Custom Web App */
  #customwebapp h1 {
    font-size: 40px;
  }

  #customwebapp p {
    font-size: 22px;
  }

  #customwebapp ul li {
    font-size: 22px;
  }

  /* Inventory Management */
  #inventorymanagement h1 {
    font-size: 40px;
  }

  #inventorymanagement h3 {
    font-size: 35px;
  }

  #inventorymanagement p {
    font-size: 22px;
  }

  #inventorymanagement ul li {
    font-size: 22px;
  }

  /* Open Source */
  #opensource h1 {
    font-size: 40px;
  }

  #opensource p {
    font-size: 22px;
  }
}

/* For Large Laptops */
@media (min-width: 1025px) and (max-width: 1280px) {
  /*(min-width: 1025px) and (max-width: 1280px)*/

  /* Navbar */
  #navbar1 {
    display: block;
  }

  #navbar2 {
    display: none;
  }

  #navbarSupportedContent ul {
    margin-left: -75px;
  }

  /* Enquiry Form */
  #eqform {
    height: 600px;
    background-image: url('/public/images/enquiry.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  #eqnform {
    margin-top: 75px;
    margin-left: 250px;
    padding: 50px;
  }

  #eqnform form {
    width: 50%;
  }

  /* Footer */
  #footercontentblock1 {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  #footercontentblock2 {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  #footercontent1 {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
    text-align: start;
  }

  #footer1 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer1 h3 {
    font-size: 25px;
    margin-left: 35px;
  }

  #footer1 .links {
    font-size: 16px;
  }
  #footer1 .links ul li a {
    transition: all 1s;
  }
  #footer1 .links ul li a:hover {
    margin-left: 5px;
  }

  #divide1 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer2 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer2 h3 {
    font-size: 25px;
    margin-left: 35px;
  }

  #footer2 .links {
    font-size: 16px;
  }
  #footer2 .links ul li a {
    transition: all 1s;
  }
  #footer2 .links ul li a:hover {
    margin-left: 5px;
  }

  #divide2 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer3 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer3 h3 {
    font-size: 25px;
    margin-left: 30px;
  }

  #footer3 .links {
    font-size: 16px;
  }
  #footer3 .links ul li a {
    transition: all 1s;
  }
  #footer3 .links ul li a:hover {
    margin-left: 5px;
  }

  #divide3 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer4 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer4 h3 {
    font-size: 25px;
    margin-left: 30px;
  }

  #footer4 .links {
    font-size: 16px;
  }
  #footer4 .links ul li a {
    transition: all 1s;
  }
  #footer4 .links ul li a:hover {
    margin-left: 5px;
  }

  #divide4 {
    width: auto;
    height: 1px;
    border: 1px solid grey;
  }

  #footertext p {
    font-size: medium;
  }

  #footertext {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  #footertext p {
    margin-top: 10px;
    font-size: 15px;
  }

  #footertext span a {
    font-size: 20px;
    text-decoration: none;
    color: white;
  }

  #footertext span a:hover {
    color: rgb(8, 131, 247);
    text-decoration: underline;
  }

  /* Terms and conditions */
  #terms {
    margin: 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #terms h3 {
    color: white;
    font-size: 30px;
    font-weight: bolder;
  }

  #terms h2 {
    color: white;
    font-size: 35px;
    font-weight: bolder;
  }

  #terms p {
    font-size: 27px;
    color: white;
  }

  #terms ul li {
    font-size: 24px;
    color: white;
  }

  /* Privacy Policy */
  #privacy {
    margin: 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #privacy h2 {
    color: white;
    font-size: 35px;
    font-weight: bolder;
  }

  #privacy h3 {
    color: white;
    font-size: 30px;
    font-weight: bolder;
  }

  #privacy p {
    color: white;
    font-size: 27px;
  }

  #privacy ul li {
    color: white;
    font-size: 24px;
  }

  #indialocation {
    margin-left: 150px;
  }

  /* Home Page */
  /* NavBar */
  .navbar {
    width: auto;
  }

  .navbar #navbarSupportedContent {
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }

  .navbar #navbarSupportedContent ul li a {
    font-size: 17px;
  }

  .navbar #logo h5 {
    font-size: 25px;
    margin-left: 15px;
    font-weight: bold;
  }

  /* carousel */
  #carouselExampleInterval {
    width: auto;
    /* height: 250px; */
    margin-left: 40px;
    margin-top: -75px;
    margin-right: 45px;
  }

  .carousel-item {
    top: 90px;
    height: 550px;
  }

  .carousel-caption {
    width: auto;
    height: 200px;
    /* margin-top: 20px; */
  }

  .carousel-caption p {
    font-size: 20px;
    font-weight: bold;
    color: white;
    /* margin-top: -450px; */
    margin-left: 250px;
  }

  #carousel1 .carousel-caption p {
    font-size: 35px;
    margin-top: -100px;
    margin-left: -200px;
  }

  #carousel2 .carousel-caption p {
    font-size: 35px;
    margin-top: -50px;
    margin-left: 550px;
  }

  #carousel3 .carousel-caption p {
    font-size: 35px;
    margin-top: -50px;
    margin-left: 300px;
  }

  /* About */
  #section1 h1 {
    width: auto;
    font-size: 35px;
  }

  #section1 p {
    width: auto;
    font-size: 20px;
  }

  #aboutusblock1 {
    display: flex;
    flex-direction: row;
  }

  #aboutuscontent1 {
    width: 50%;
    text-align: start;
  }

  #aboutusbtn1 {
    width: 150px;
    padding: 10px;
  }

  #aboutusimg1 {
    width: 50%;
  }

  #aboutusimg1 video {
    width: 100%;
    height: 300px;
  }

  #aboutusimg1 img {
    width: 400px;
    height: 350px;
  }

  /* Corporate */
  #section21 {
    height: auto;
    padding-right: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
  }

  #corporateblock .cards1 {
    display: flex;
  }

  #corporateblock .cards1 .text-cards1 {
    width: 275px;
    height: 275px;
    background-color: rgb(7, 230, 7);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards1 .text-cards1 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #corporateblock .cards2 {
    display: flex;
  }

  #corporateblock .cards2 .text-cards2 {
    width: 275px;
    height: 275px;
    background-color: rgb(245, 10, 233);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards2 .text-cards2 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #corporateblock .cards3 {
    display: flex;
  }

  #corporateblock .cards3 .text-cards3 {
    width: 275px;
    height: 275px;
    background-color: rgb(230, 178, 7);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards3 .text-cards3 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }


  #section2 h2 {
    width: 100%;
    font-size: 35px;
  }

  #section2 p {
    width: 100%;
    font-size: 25px;
  }

  #section2 #bigdata img {
    width: 400px;
    height: 300px;
  }

  #section3 #bigdata img {
    width: 400px;
    height: 300px;
  }

  #section3 h2 {
    width: 100%;
    font-size: 35px;
  }

  #section3 p {
    width: 100%;
    font-size: 25px;
  }

  #section4 h2 {
    width: 100%;
    font-size: 35px;
  }

  #section4 p {
    width: 100%;
    font-size: 25px;
  }

  #section4 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section5 #bigdata img {
    width: 400px;
    height: 300px;
  }

  #section5 h2 {
    width: 100%;
    font-size: 35px;
  }

  #section5 p {
    width: 100%;
    font-size: 25px;
  }

  #section6 h1 {
    width: 100%;
    font-size: 35px;
  }

  #section6 p {
    width: 100%;
    font-size: 20px;
  }


  /* Solutions */
  #section7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section8 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section9 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #solutions section .cards12 {
    display: flex;
  }

  #solutions section .cards12 .text-cards12 {
    width: 250px;
    height: 250px;
    background-color: rgb(5, 122, 255);
    margin: 15px;
    padding: 15px;
  }

  #solutions section .cards12 .text-cards12 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  #solutions section .cards13 {
    display: flex;
  }

  #solutions section .cards13 .text-cards13 {
    width: 250px;
    height: 250px;
    background-color: rgb(247, 151, 8);
    margin: 15px;
    padding: 15px;
  }

  #solutions section .cards13 .text-cards13 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Third section */
  #solutions section .cards14 {
    display: flex;
  }

  #solutions section .cards14 .text-cards14 {
    width: 250px;
    height: 250px;
    background-color: rgb(196, 11, 165);
    margin: 15px;
    padding: 15px;
  }

  #solutions section .cards14 .text-cards14 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fourth section */
  #solutions section .cards15 {
    display: flex;
  }

  #solutions section .cards15 .text-cards15 {
    width: 250px;
    height: 250px;
    background-color: rgb(10, 238, 181);
    margin: 15px;
    padding: 15px;
  }

  #solutions section .cards15 .text-cards15 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fifth section */
  #solutions section .cards16 {
    display: flex;
  }

  #solutions section .cards16 .text-cards16 {
    width: 250px;
    height: 250px;
    background-color: rgb(248, 244, 7);
    margin: 15px;
    padding: 15px;
  }

  #solutions section .cards16 .text-cards16 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }


  /* Our services */
  #section31 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section32 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section33 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards1 {
    display: flex;
  }

  section .cards1 .text-cards1 {
    width: 250px;
    height: 250px;
    background-color: rgb(7, 185, 230);
    margin: 15px;
    padding: 15px;
  }

  section .cards1 .text-cards1 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  section .cards2 {
    display: flex;
  }

  section .cards2 .text-cards2 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 15px;
    padding: 15px;
  }

  section .cards2 .text-cards2 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Third section */
  section .cards3 {
    display: flex;
  }

  section .cards3 .text-cards3 {
    width: 250px;
    height: 250px;
    background-color: rgb(252, 4, 157);
    margin: 15px;
    padding: 15px;
  }

  section .cards3 .text-cards3 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Fourth section */
  section .cards4 {
    display: flex;
  }

  section .cards4 .text-cards4 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 15px;
    padding: 15px;
  }

  section .cards4 .text-cards4 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Fifth section */
  section .cards5 {
    display: flex;
  }

  section .cards5 .text-cards5 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 15px;
    padding: 15px;
  }

  section .cards5 .text-cards5 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }


  /* Industries */
  #section41 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section42 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section43 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards6 {
    display: flex;
  }

  section .cards6 .text-cards6 {
    width: 250px;
    height: 250px;
    background-color: rgb(250, 7, 68);
    margin: 15px;
    padding: 15px;
  }

  section .cards6 .text-cards6 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  section .cards7 {
    display: flex;
  }

  section .cards7 .text-cards7 {
    width: 250px;
    height: 250px;
    background-color: rgb(46, 245, 7);
    margin: 15px;
    padding: 15px;
  }

  section .cards7 .text-cards7 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Third section */
  section .cards8 {
    display: flex;
  }

  section .cards8 .text-cards8 {
    width: 250px;
    height: 250px;
    background-color: rgb(173, 4, 252);
    margin: 15px;
    padding: 15px;
  }

  section .cards8 .text-cards8 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fourth section */
  section .cards9 {
    display: flex;
  }

  section .cards9 .text-cards9 {
    width: 250px;
    height: 250px;
    background-color: rgb(248, 5, 187);
    margin: 15px;
    padding: 15px;
  }

  section .cards9 .text-cards9 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fifth section */
  section .cards10 {
    display: flex;
  }

  section .cards10 .text-cards10 {
    width: 250px;
    height: 250px;
    background-color: rgb(238, 118, 5);
    margin: 15px;
    padding: 15px;
  }

  section .cards10 .text-cards10 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Sixth section */
  section .cards11 {
    display: flex;
  }

  section .cards11 .text-cards11 {
    width: 250px;
    height: 250px;
    background-color: rgb(11, 193, 238);
    margin: 15px;
    padding: 15px;
  }

  section .cards11 .text-cards11 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }


  #section10 h1 {
    font-size: 35px;
  }

  #section11 #bigdata img {
    width: 300px;
    height: 300px;
  }

  #section11 h2 {
    width: 100%;
    font-size: 35px;
  }

  #section11 p {
    width: 100%;
    font-size: 25px;
  }

  #section12 #bigdata img {
    width: 300px;
    height: 300px;
  }

  #section12 h2 {
    width: 100%;
    font-size: 35px;
  }

  #section12 p {
    width: 100%;
    font-size: 25px;
  }

  #section13 h2 {
    width: auto;
    font-size: 35px;
  }

  #section13 p {
    width: auto;
    font-size: 25px;
  }


  /* Aboutus */
  #aboutusblock {
    display: flex;
    flex-direction: row;
  }

  #aboutuscontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #aboutusimage {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  #aboutusimage img {
    width: 100%;
    height: 400px;
  }

  #aboutuscontent p {
    width: 100%;
    font-size: 20px;
  }

  /* Vision & Mission */
  #visionandmission h1 {
    font-size: 35px;
  }

  #visionandmission h4 {
    font-size: 30px;
  }

  #visionandmission p {
    font-size: 20px;
  }

  #visionandmission img {
    width: 100%;
    height: 200px;
  }

  /* Core value */
  #corevalue h1 {
    font-size: 35px;
  }

  #corevalue h3 {
    font-size: 30px;
    text-align: start;
  }

  #corevalue ul li {
    font-size: 20px;
  }

  /* Social Responsibility */
  #socialresponsibilityblock {
    display: flex;
    flex-direction: row;
  }

  #socialresponsibilitycontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #socialresponsibilityimg {
    width: 40%;
    padding-left: 45px;
    padding-top: 50px;
  }

  #socialresponsibilityimg img {
    width: 100%;
    height: 350px;
  }

  #socialresponsibility h1 {
    font-size: 35px;
  }

  #socialresponsibility h2 {
    font-size: 30px;
  }

  #socialresponsibility p {
    font-size: 20px;
  }

  /* Location */
  #location h1 {
    font-size: 35px;
  }

  #location h2 {
    font-size: 30px;
  }

  #location h3 {
    font-size: 25px;
  }

  #location p {
    font-size: 20px;
  }

  #locationbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #locationbtn #btn1 {
    width: 150px;
    font-size: 25px;
    margin-right: 25px;
  }

  #locationbtn #btn2 {
    width: 150px;
    font-size: 25px;
  }

  #locationblock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #locationblock #indialocation img {
    height: 300px;
  }

  #locationblock #vizaglocation img {
    height: 300px;
  }

  #malaysialocation img {
    height: 300px;
  }

  /* Teams */
  #teams {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  #teamsblock1 {
    margin-right: 50px;
  }

  #teamsblock1 .card {
    width: 300px;
    height: 350px;
    border: none;
  }

  #teamsblock1 .card img {
    width: 100%;
    height: 250px;
  }

  #teamsblock1 .card-body span {
    font-size: 20px;
    font-weight: bold;
  }

  #teamsblock1 .card-body p {
    font-size: 20px;
  }

  #teamsblock2 .card {
    width: 300px;
    height: 350px;
    border: none;
  }

  #teamsblock2 .card img {
    width: 100%;
    height: 250px;
  }

  #teamsblock2 .card-body span {
    font-size: 20px;
    font-weight: bold;
  }

  #teamsblock2 .card-body p {
    font-size: 20px;
  }


  #teamsblock1 .modal-content {
    width: 1000px;
    height: 550px;
    left: -250px;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock1 .modal-body {
    width: 40%;
  }

  #teamsblock1 .modal-body img {
    width: 350px;
    height: 350px;
    margin-top: 75px;
  }

  #teamsblock1 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    overflow: scroll;
  }

  #teamsblock1 .modal-title {
    margin-right: 150px;
  }

  #teamsblock1 .modal-text {
    font-size: 22px;
  }

  #teamsblock1 .modal-header .text {
    font-size: 18px;
    text-align: justify;
  }

  #teamsblock2 .modal-content {
    width: 1000px;
    height: 550px;
    left: -250px;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock2 .modal-body {
    width: 40%;
  }

  #teamsblock2 .modal-body img {
    width: 350px;
    height: 350px;
    margin-top: 75px;
  }

  #teamsblock2 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    overflow: scroll;
  }

  #teamsblock2 .modal-title {
    margin-right: 240px;
  }

  #teamsblock2 .modal-text {
    font-size: 22px;
  }

  #teamsblock2 .modal-header .text {
    font-size: 18px;
    text-align: justify;
  }


  /* Industries */
  /* Financial Services */
  #financialservice h1 {
    font-size: 35px;
  }

  #financialservice h4 {
    font-size: 30px;
  }

  #financialservice p {
    font-size: 20px;
  }

  #financialservice img {
    width: 100%;
    height: 350px;
  }

  #financialserviceblock {
    display: flex;
    flex-direction: row;
  }

  #financialservicecontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #financialserviceimg {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  /* Government */
  #government h1 {
    font-size: 35px;
  }

  #government h3 {
    font-size: 30px;
  }

  #government p {
    font-size: 20px;
  }

  #government ul li {
    font-size: 20px;
  }

  /* Manufacturing */
  #manufacturing h1 {
    font-size: 35px;
  }

  #manufacturing p {
    font-size: 20px;
  }

  #manufacturing ul li {
    font-size: 20px;
  }

  /* Retail */
  #retail h1 {
    font-size: 35px;
  }

  #retail img {
    width: 100%;
    height: 400px;
  }

  #retail p {
    font-size: 20px;
  }

  #retail ul li {
    font-size: 20px;
  }

  #retail #img {
    width: 40%;
    padding-left: 50px;
    padding-right: 50px;
  }

  #retail #retailcontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #retailblock {
    display: flex;
    flex-direction: row;
  }

  /* Telecommunication */
  #telecommunication h1 {
    font-size: 35px;
  }

  #telecommunication h4 {
    font-size: 30px;
  }

  #telecommunication p {
    font-size: 20px;
  }

  #telecommunication ul li {
    font-size: 20px;
  }

  #telecommunication img {
    width: 100%;
    height: 350px;
  }

  #telecommunicationblock {
    display: flex;
    flex-direction: row;
  }

  #telecommunicationcontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #telecommunicationimg {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  /* Utilities */
  #utilities h1 {
    font-size: 35px;
  }

  #utilities h3 {
    font-size: 30px;
  }

  #utilities p {
    font-size: 20px;
  }

  #utilities ul li {
    font-size: 20px;
  }

  /* Products */
  /* Log-Mode */
  #logmode h1 {
    font-size: 35px;
  }

  #logmode h4 {
    font-size: 30px;
  }

  #logmode h5 {
    font-size: 25px;
  }

  #logmode p {
    font-size: 20px;
  }

  #logmode img {
    width: 500px;
    height: 250px;
  }

  #logmodeblock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
  }

  /* Solutions */
  /* Application Middleware */
  #applicationmiddleware h1 {
    font-size: 35px;
  }

  #applicationmiddleware h3 {
    font-size: 30px;
  }

  #applicationmiddleware p {
    font-size: 20px;
  }

  #applicationmiddleware ul li {
    font-size: 20px;
  }

  /* Big Data */
  #bigdata h1 {
    font-size: 35px;
  }

  #bigdata h2 {
    font-size: 30px;
  }

  #bigdata p,
  b {
    font-size: 20px;
  }

  #bigdata ul li {
    font-size: 20px;
  }

  #bigdata img {
    width: 500px;
    height: 250px;
    margin-left: 300px;
  }

  /* Business Intelligence Solutions */
  #businessintelligence h1 {
    font-size: 35px;
  }

  #businessintelligence h3 {
    font-size: 30px;
  }

  #businessintelligence p,
  b {
    font-size: 20px;
  }

  #businessintelligence ul li {
    font-size: 20px;
  }

  #businessintelligenceblock1 {
    display: flex;
    flex-direction: row;
  }

  #businessintelligenceblock1 p {
    width: 50%;
    padding: 5px;
  }

  #businessintelligenceblock1 img {
    width: 50%;
    height: 350px;
  }

  #businessintelligenceblock2 {
    display: flex;
    flex-direction: row;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 img {
    width: 150%;
    height: 300px;
    margin-left: -150px;
  }

  /* Data Management */
  #datamanagement h1 {
    font-size: 35px;
  }

  #datamanagement h3 {
    font-size: 30px;
  }

  #datamanagement p,
  b {
    font-size: 20px;
  }

  #datamanagement ul li {
    font-size: 20px;
  }

  #datamanagement img {
    width: 60%;
    height: 250px;
  }

  #datamanagementblock {
    display: flex;
    flex-direction: row;
  }

  #datamanagementcontent {
    width: 50%;
  }

  #datamanagementimg {
    width: 50%;
  }

  /* Enterprise Data Warehouse */
  #enterprisedata h1 {
    font-size: 35px;
  }

  #enterprisedata h2 {
    font-size: 30px;
  }

  #enterprisedata p,
  b {
    font-size: 20px;
  }

  #enterprisedata ul li {
    font-size: 20px;
  }

  #enterprisedata img {
    width: 100%;
    height: 250px;
  }

  #enterprisedatablock {
    display: flex;
    flex-direction: row;
  }

  #enterprisedatacontent {
    width: 50%;
  }

  #enterprisedataimg {
    width: 50%;
  }


  /* Services */
  /* Business Intelligence Services */
  #businessIntelligence h1 {
    font-size: 35px;
  }

  #businessIntelligence h2 {
    font-size: 30px;
  }

  #businessIntelligence h4 {
    font-size: 25px;
  }

  #businessIntelligence p,
  b {
    font-size: 20px;
  }

  #businessIntelligence ul li {
    font-size: 20px;
  }

  /* Enterprise Mobility Service */
  #enterprisemobility h1 {
    font-size: 35px;
  }

  #enterprisemobility p {
    font-size: 20px;
  }

  #enterprisemobility ul li {
    font-size: 20px;
  }

  /* Mobile Apps */
  #mobileapps h1 {
    font-size: 35px;
  }

  #mobileapps p {
    font-size: 20px;
  }

  #mobileapps ul li {
    font-size: 20px;
  }

  /* Testing And QA Services */
  #testingandqa h1 {
    font-size: 35px;
  }

  #testingandqa p {
    font-size: 20px;
  }

  #testingandqa ul li {
    font-size: 20px;
  }

  /* Web Application */
  #webapplication h1 {
    font-size: 35px;
  }

  #webapplication p {
    font-size: 20px;
  }

  #webapplication ul li {
    font-size: 20px;
  }


  /* Enterprise Mobility */
  /* Business Process */
  #businessprocess h1 {
    font-size: 35px;
  }

  #businessprocess p {
    font-size: 20px;
  }

  /* B2B Mobile */
  #b2bmobile h1 {
    font-size: 35px;
  }

  #b2bmobile h2 {
    font-size: 30px;
  }

  #b2bmobile p {
    font-size: 20px;
  }

  #b2bmobile ul li {
    font-size: 20px;
  }

  /* Android App */
  #enterpriseandroid h1 {
    font-size: 35px;
  }

  #enterpriseandroid p {
    font-size: 20px;
  }

  #enterpriseandroid ul li {
    font-size: 20px;
  }

  /* Enterprise APP */
  #enterpriseapp h1 {
    font-size: 35px;
  }

  #enterpriseapp h3 {
    font-size: 30px;
  }

  #enterpriseapp p {
    font-size: 20px;
  }

  /* Enterprise IOS App */
  #enterpriseiosapp h1 {
    font-size: 35px;
  }

  #enterpriseiosapp h3 {
    font-size: 30px;
  }

  #enterpriseiosapp p {
    font-size: 20px;
  }

  /* Mobile Business */
  #mobilebusiness h1 {
    font-size: 35px;
  }

  #mobilebusiness p {
    font-size: 20px;
  }


  /* Mobile Apps */
  /* Android App */
  #androidapp h1 {
    font-size: 35px;
  }

  #androidapp p {
    font-size: 20px;
  }

  /* Blackberry App */
  #blackberryapp h1 {
    font-size: 35px;
  }

  #blackberryapp p {
    font-size: 20px;
  }

  /* Iphone App */
  #iphoneapp h1 {
    font-size: 35px;
  }

  #iphoneapp p {
    font-size: 20px;
  }

  /* Windows8 App */
  #windows8app h1 {
    font-size: 35px;
  }

  #windows8app p {
    font-size: 20px;
  }


  /* Testing & QA Services*/
  /* Automated Testing */
  #automatedtesting h1 {
    font-size: 35px;
  }

  #automatedtesting h3 {
    font-size: 30px;
  }

  #automatedtesting p {
    font-size: 20px;
  }

  #automatedtesting ul li {
    font-size: 20px;
  }

  /* Manual Testing */
  #manualtesting h1 {
    font-size: 35px;
  }

  #manualtesting h3 {
    font-size: 30px;
  }

  #manualtesting p {
    font-size: 20px;
  }

  #manualtesting ul li {
    font-size: 20px;
  }

  /* QTP */
  #qtp h1 {
    font-size: 35px;
  }

  #qtp h3 {
    font-size: 30px;
  }

  #qtp p {
    font-size: 20px;
  }

  #qtp ul li {
    font-size: 20px;
  }

  /* Selenium */
  #selenium h1 {
    font-size: 35px;
  }

  #selenium h3 {
    font-size: 30px;
  }

  #selenium p {
    font-size: 20px;
  }

  #selenium ul li {
    font-size: 20px;
  }

  /* Test Complete */
  #testcomplete h1 {
    font-size: 35px;
  }

  #testcomplete h3 {
    font-size: 30px;
  }

  #testcomplete p {
    font-size: 20px;
  }

  #testcomplete ul li {
    font-size: 20px;
  }


  /* Web Application */
  /* CRM Application */
  #cmrapplication h1 {
    font-size: 35px;
  }

  #cmrapplication p {
    font-size: 20px;
  }

  /* Content Management */
  #contentmanagement h1 {
    font-size: 35px;
  }

  #contentmanagement h3 {
    font-size: 30px;
  }

  #contentmanagement p {
    font-size: 20px;
  }

  #contentmanagement ul li {
    font-size: 20px;
  }

  /* Custom Web App */
  #customwebapp h1 {
    font-size: 35px;
  }

  #customwebapp p {
    font-size: 20px;
  }

  #customwebapp ul li {
    font-size: 20px;
  }

  /* Inventory Management */
  #inventorymanagement h1 {
    font-size: 35px;
  }

  #inventorymanagement h3 {
    font-size: 30px;
  }

  #inventorymanagement p {
    font-size: 20px;
  }

  #inventorymanagement ul li {
    font-size: 20px;
  }

  /* Open Source */
  #opensource h1 {
    font-size: 35px;
  }

  #opensource p {
    font-size: 20px;
  }
}

/* For small and medium Laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  /*(min-width: 768px) and (max-width: 1024px)*/

  /* Navbar */
  #navbar1 {
    display: none;
  }

  #navbar2 {
    display: block;
  }

  #navbar2 #logo h5 {
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
  }

  #navbar2 .offcanvas-body {
    margin-top: -50px;
    text-align: start;
  }

  #navbar2 .offcanvas-body ul li a {
    color: white;
  }

  #navbar2 .offcanvas-body ul li a:hover {
    color: rgb(255, 7, 255);
  }

  /* Enquiry Form */
  #eqform {
    height: 600px;
    background-image: url('/public/images/enquiry.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  #eqnform {
    margin-top: 75px;
    margin-left: 200px;
    padding: 50px;
  }

  #eqnform form {
    width: 50%;
  }

  /* Footer */
  #footercontentblock1 {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  #footercontentblock2 {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  #footercontent1 {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
    text-align: start;
  }

  #footer1 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer1 h3 {
    font-size: 25px;
    margin-left: 30px;
  }

  #footer1 .links {
    font-size: 11.5px;
  }
  #footer1 .links ul li a {
    transition: all 1s;
  }
  #footer1 .links ul li a:hover {
    margin-left: 10px;
  }

  #divide1 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer2 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer2 h3 {
    font-size: 25px;
    margin-left: 35px;
  }

  #footer2 .links {
    font-size: 11.5px;
  }
  #footer2 .links ul li a {
    transition: all 1s;
  }
  #footer2 .links ul li a:hover {
    margin-left: 10px;
  }

  #divide2 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer3 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer3 h3 {
    font-size: 25px;
    margin-left: 30px;
  }

  #footer3 .links {
    font-size: 11.5px;
  }
  #footer3 .links ul li a {
    transition: all 1s;
  }
  #footer3 .links ul li a:hover {
    margin-left: 10px;
  }

  #divide3 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer4 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer4 h3 {
    font-size: 25px;
    margin-left: 30px;
  }

  #footer4 .links {
    font-size: 11.5px;
  }
  #footer4 .links ul li a {
    transition: all 1s;
  }
  #footer4 .links ul li a:hover {
    margin-left: 10px;
  }

  #divide4 {
    width: auto;
    height: 1px;
    border: 1px solid grey;
  }

  #footertext p {
    font-size: small;
  }

  #footertext {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  #footertext p {
    margin-top: 10px;
    font-size: 15px;
  }

  #footertext span a {
    font-size: 15px;
    text-decoration: none;
    color: white;
  }

  #footertext span a:hover {
    color: rgb(8, 131, 247);
    text-decoration: underline;
  }

  /* Terms and conditions */
  #terms {
    margin: 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #terms h3 {
    color: white;
    font-size: 30px;
    font-weight: bolder;
  }

  #terms h2 {
    color: white;
    font-size: 35px;
    font-weight: bolder;
  }

  #terms p {
    font-size: 27px;
    color: white;
  }

  #terms ul li {
    font-size: 24px;
    color: white;
  }

  /* Privacy Policy */
  #privacy {
    margin: 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #privacy h2 {
    color: white;
    font-size: 35px;
    font-weight: bolder;
  }

  #privacy h3 {
    color: white;
    font-size: 30px;
    font-weight: bolder;
  }

  #privacy p {
    color: white;
    font-size: 27px;
  }

  #privacy ul li {
    color: white;
    font-size: 24px;
  }

  #indialocation {
    margin-left: 75px;
  }

  /* Home Page */
  /* carousel */
  #carouselExampleInterval {
    width: auto;
    /* height: 300px; */
    margin-left: 40px;
    margin-top: -75px;
    margin-right: 45px;
  }

  .carousel-item {
    top: 90px;
    height: 450px;
  }

  .carousel-caption {
    width: auto;
    height: 200px;
    /* margin-top: 20px; */
  }

  .carousel-caption p {
    font-size: 15px;
    font-weight: bold;
    color: white;
    /* margin-top: -450px; */
    margin-left: 200px;
  }

  #carousel1 .carousel-caption p {
    font-size: 35px;
    margin-top: -100px;
    margin-left: -200px;
  }

  #carousel2 .carousel-caption p {
    font-size: 35px;
    margin-top: -50px;
    margin-left: 550px;
  }

  #carousel3 .carousel-caption p {
    font-size: 35px;
    margin-top: -50px;
    margin-left: 300px;
  }

  /* About */
  #section1 h1 {
    width: auto;
    font-size: 30px;
  }

  #section1 h3 {
    width: auto;
    font-size: 20px;
  }

  #section1 p {
    width: auto;
    font-size: small;
  }

  #aboutusblock1 {
    display: flex;
    flex-direction: row;
  }

  #aboutuscontent1 {
    width: 50%;
    text-align: start;
  }

  #aboutusbtn1 {
    width: 150px;
    padding: 10px;
  }

  #aboutusimg1 {
    width: 50%;
  }

  #aboutusimg1 video {
    width: 100%;
    height: 250px;
  }

  #aboutusimg1 img {
    width: 300px;
    height: 300px;
  }

  /* Corporate */
  #section21 {
    height: auto;
    padding-right: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #corporateblock .cards1 {
    display: flex;
  }

  #corporateblock .cards1 .text-cards1 {
    width: 250px;
    height: 300px;
    background-color: rgb(7, 230, 7);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards1 .text-cards1 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #corporateblock .cards2 {
    display: flex;
  }

  #corporateblock .cards2 .text-cards2 {
    width: 250px;
    height: 300px;
    background-color: rgb(245, 10, 233);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards2 .text-cards2 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #corporateblock .cards3 {
    display: flex;
  }

  #corporateblock .cards3 .text-cards3 {
    width: 250px;
    height: 300px;
    background-color: rgb(230, 178, 7);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards3 .text-cards3 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #section2 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section2 p {
    width: 100%;
    font-size: 20px;
  }

  #section2 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section3 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section3 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section3 p {
    width: 100%;
    font-size: 20px;
  }

  #section4 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section4 p {
    width: 100%;
    font-size: 20px;
  }

  #section4 #bigdata img {
    width: 200px;
    height: 200px;
  }

  #section5 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section5 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section5 p {
    width: 100%;
    font-size: 20px;
  }

  #section6 h1 {
    width: 100%;
    font-size: 30px;
  }

  #section6 p {
    width: 100%;
    font-size: 15px;
  }


  /* Solutions */
  #section7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section8 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section9 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #solutions section .cards12 {
    display: flex;
  }

  #solutions section .cards12 .text-cards12 {
    width: 250px;
    height: 250px;
    background-color: rgb(5, 122, 255);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards12 .text-cards12 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  #solutions section .cards13 {
    display: flex;
  }

  #solutions section .cards13 .text-cards13 {
    width: 250px;
    height: 250px;
    background-color: rgb(247, 151, 8);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards13 .text-cards13 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Third section */
  #solutions section .cards14 {
    display: flex;
  }

  #solutions section .cards14 .text-cards14 {
    width: 250px;
    height: 250px;
    background-color: rgb(196, 11, 165);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards14 .text-cards14 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fourth section */
  #solutions section .cards15 {
    display: flex;
  }

  #solutions section .cards15 .text-cards15 {
    width: 250px;
    height: 250px;
    background-color: rgb(10, 238, 181);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards15 .text-cards15 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fifth section */
  #solutions section .cards16 {
    display: flex;
  }

  #solutions section .cards16 .text-cards16 {
    width: 250px;
    height: 250px;
    background-color: rgb(248, 244, 7);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards16 .text-cards16 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }


  /* Our services */
  #section31 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section32 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section33 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards1 {
    display: flex;
  }

  section .cards1 .text-cards1 {
    width: 250px;
    height: 250px;
    background-color: rgb(7, 185, 230);
    margin: 10px;
    padding: 15px;
  }

  section .cards1 .text-cards1 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  section .cards2 {
    display: flex;
  }

  section .cards2 .text-cards2 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards2 .text-cards2 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Third section */
  section .cards3 {
    display: flex;
  }

  section .cards3 .text-cards3 {
    width: 250px;
    height: 250px;
    background-color: rgb(252, 4, 157);
    margin: 10px;
    padding: 15px;
  }

  section .cards3 .text-cards3 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Fourth section */
  section .cards4 {
    display: flex;
  }

  section .cards4 .text-cards4 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards4 .text-cards4 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Fifth section */
  section .cards5 {
    display: flex;
  }

  section .cards5 .text-cards5 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards5 .text-cards5 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }


  /* Industries */
  #section41 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section42 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section43 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards6 {
    display: flex;
  }

  section .cards6 .text-cards6 {
    width: 250px;
    height: 250px;
    background-color: rgb(250, 7, 68);
    margin: 10px;
    padding: 15px;
  }

  section .cards6 .text-cards6 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  section .cards7 {
    display: flex;
  }

  section .cards7 .text-cards7 {
    width: 250px;
    height: 250px;
    background-color: rgb(46, 245, 7);
    margin: 10px;
    padding: 15px;
  }

  section .cards7 .text-cards7 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Third section */
  section .cards8 {
    display: flex;
  }

  section .cards8 .text-cards8 {
    width: 250px;
    height: 250px;
    background-color: rgb(173, 4, 252);
    margin: 10px;
    padding: 15px;
  }

  section .cards8 .text-cards8 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fourth section */
  section .cards9 {
    display: flex;
  }

  section .cards9 .text-cards9 {
    width: 250px;
    height: 250px;
    background-color: rgb(248, 5, 187);
    margin: 10px;
    padding: 15px;
  }

  section .cards9 .text-cards9 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fifth section */
  section .cards10 {
    display: flex;
  }

  section .cards10 .text-cards10 {
    width: 250px;
    height: 250px;
    background-color: rgb(238, 118, 5);
    margin: 10px;
    padding: 15px;
  }

  section .cards10 .text-cards10 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Sixth section */
  section .cards11 {
    display: flex;
  }

  section .cards11 .text-cards11 {
    width: 250px;
    height: 250px;
    background-color: rgb(11, 193, 238);
    margin: 10px;
    padding: 15px;
  }

  section .cards11 .text-cards11 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }


  #section10 h1 {
    font-size: 30px;
  }

  #section11 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section11 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section11 p {
    width: 100%;
    font-size: 20px;
  }

  #section12 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section12 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section12 p {
    width: 100%;
    font-size: 20px;
  }

  #section13 h2 {
    width: auto;
    font-size: 30px;
  }

  #section13 p {
    width: auto;
    font-size: 20px;
  }


  /* Aboutus */
  #aboutusblock {
    display: flex;
    flex-direction: row;
  }

  #aboutuscontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #aboutusimage {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  #aboutusimage img {
    width: 100%;
    height: 300px;
  }

  #aboutuscontent p {
    width: 100%;
    font-size: 20px;
  }

  /* Vision & Mission */
  #visionandmission h1 {
    font-size: 30px;
  }

  #visionandmission h4 {
    font-size: 25px;
  }

  #visionandmission p {
    font-size: 20px;
  }

  #visionandmission img {
    width: 100%;
    height: 200px;
  }

  /* Core value */
  #corevalue h1 {
    font-size: 30px;
  }

  #corevalue h3 {
    font-size: 25px;
  }

  #corevalue ul li {
    font-size: 20px;
  }

  /* Social Responsibility */
  #socialresponsibilityblock {
    display: flex;
    flex-direction: row;
  }

  #socialresponsibilitycontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #socialresponsibilityimg {
    width: 40%;
    padding-left: 45px;
    padding-top: 50px;
  }

  #socialresponsibilityimg img {
    width: 100%;
    height: 300px;
  }

  #socialresponsibility h1 {
    font-size: 30px;
  }

  #socialresponsibility h2 {
    font-size: 25px;
  }

  #socialresponsibility p {
    font-size: 20px;
  }

  /* Location */
  #location h1 {
    font-size: 30px;
  }

  #location h2 {
    font-size: 25px;
  }

  #location h3 {
    font-size: 20px;
  }

  #location p {
    font-size: 15px;
  }

  #locationbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #locationbtn #btn1 {
    width: 150px;
    font-size: 25px;
    margin-right: 25px;
  }

  #locationbtn #btn2 {
    width: 150px;
    font-size: 25px;
  }

  #locationblock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #locationblock #indialocation img {
    height: 300px;
  }

  #locationblock #vizaglocation img {
    height: 300px;
  }

  #malaysialocation img {
    height: 300px;
  }

  /* Teams */
  #teams {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  #teamsblock1 {
    margin-right: 50px;
  }

  #teamsblock1 .card {
    width: 300px;
    height: 350px;
    border: none;
  }

  #teamsblock1 .card img {
    width: 100%;
    height: 250px;
  }

  #teamsblock1 .card-body span {
    font-size: 20px;
    font-weight: bold;
  }

  #teamsblock1 .card-body p {
    font-size: 20px;
  }

  #teamsblock2 .card {
    width: 300px;
    height: 350px;
    border: none;
  }

  #teamsblock2 .card img {
    width: 100%;
    height: 250px;
  }

  #teamsblock2 .card-body span {
    font-size: 20px;
    font-weight: bold;
  }

  #teamsblock2 .card-body p {
    font-size: 20px;
  }


  #teamsblock1 .modal-content {
    width: 700px;
    height: 550px;
    left: -150px;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock1 .modal-body {
    width: 40%;
  }

  #teamsblock1 .modal-body img {
    width: 350px;
    height: 350px;
    margin-top: 75px;
  }

  #teamsblock1 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    overflow: scroll;
  }

  #teamsblock1 .modal-title {
    margin-right: 150px;
  }

  #teamsblock1 .modal-text {
    font-size: 22px;
  }

  #teamsblock1 .modal-header .text {
    font-size: 18px;
    text-align: justify;
  }

  #teamsblock2 .modal-content {
    width: 700px;
    height: 550px;
    left: -150px;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock2 .modal-body {
    width: 40%;
  }

  #teamsblock2 .modal-body img {
    width: 350px;
    height: 350px;
    margin-top: 75px;
  }

  #teamsblock2 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    overflow: scroll;
  }

  #teamsblock2 .modal-title {
    margin-right: 240px;
  }

  #teamsblock2 .modal-text {
    font-size: 22px;
  }

  #teamsblock2 .modal-header .text {
    font-size: 18px;
    text-align: justify;
  }

  /* Industries */
  /* Financial Services */
  #financialservice h1 {
    font-size: 30px;
  }

  #financialservice h4 {
    font-size: 25px;
  }

  #financialservice p {
    font-size: 20px;
  }

  #financialservice img {
    width: 75%;
    height: 250px;
  }

  #financialserviceblock {
    display: flex;
    flex-direction: row;
  }

  #financialservicecontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #financialserviceimg {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  /* Government */
  #government h1 {
    font-size: 30px;
  }

  #government h3 {
    font-size: 25px;
  }

  #government p {
    font-size: 20px;
  }

  #government ul li {
    font-size: 15px;
  }

  /* Manufacturing */
  #manufacturing h1 {
    font-size: 30px;
  }

  #manufacturing p {
    font-size: 20px;
  }

  #manufacturing ul li {
    font-size: 15px;
  }

  /* Retail */
  #retail h1 {
    font-size: 30px;
  }

  #retail img {
    width: 100%;
    height: 250px;
  }

  #retail p {
    font-size: 20px;
  }

  #retail ul li {
    font-size: 15px;
  }

  #retail #img {
    width: 40%;
    padding-left: 50px;
    padding-right: 50px;
  }

  #retail #retailcontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #retailblock {
    display: flex;
    flex-direction: row;
  }

  /* Telecommunication */
  #telecommunication h1 {
    font-size: 30px;
  }

  #telecommunication h4 {
    font-size: 25px;
  }

  #telecommunication p {
    font-size: 20px;
  }

  #telecommunication ul li {
    font-size: 15px;
  }

  #telecommunication img {
    width: 100%;
    height: 250px;
  }

  #telecommunicationblock {
    display: flex;
    flex-direction: row;
  }

  #telecommunicationcontent {
    width: 60%;
    padding: 50px;
    text-align: justify;
  }

  #telecommunicationimg {
    width: 40%;
    padding-right: 50px;
    padding-top: 50px;
  }

  /* Utilities */
  #utilities h1 {
    font-size: 30px;
  }

  #utilities h3 {
    font-size: 25px;
  }

  #utilities p {
    font-size: 20px;
  }

  #utilities ul li {
    font-size: 15px;
  }

  /* Products */
  /* Log-Mode */
  #logmode h1 {
    font-size: 30px;
  }

  #logmode h4 {
    font-size: 25px;
  }

  #logmode h5 {
    font-size: 20px;
  }

  #logmode p {
    font-size: 15px;
  }

  #logmode img {
    width: 400px;
    height: 200px;
  }

  #logmodeblock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
  }

  /* Solutions */
  /* Application Middleware */
  #applicationmiddleware h1 {
    font-size: 30px;
  }

  #applicationmiddleware h3 {
    font-size: 25px;
  }

  #applicationmiddleware p {
    font-size: 20px;
  }

  #applicationmiddleware ul li {
    font-size: 15px;
  }

  /* Big Data */
  #bigdata h1 {
    font-size: 30px;
  }

  #bigdata h2 {
    font-size: 25px;
  }

  #bigdata p,
  b {
    font-size: 20px;
  }

  #bigdata ul li {
    font-size: 15px;
  }

  #bigdata img {
    width: 400px;
    height: 250px;
    margin-left: 250px;
  }

  /* Business Intelligence Solutions */
  #businessintelligence h1 {
    font-size: 30px;
  }

  #businessintelligence h3 {
    font-size: 25px;
  }

  #businessintelligence p,
  b {
    font-size: 20px;
  }

  #businessintelligence ul li {
    font-size: 15px;
  }

  #businessintelligenceblock1 {
    display: flex;
    flex-direction: row;
  }

  #businessintelligenceblock1 p {
    width: 50%;
    padding: 5px;
  }

  #businessintelligenceblock1 img {
    width: 50%;
    height: 450px;
  }

  #businessintelligenceblock2 {
    display: flex;
    flex-direction: row;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 img {
    width: 150%;
    height: 250px;
    margin-left: -150px;
  }

  /* Data Management */
  #datamanagement h1 {
    font-size: 30px;
  }

  #datamanagement h3 {
    font-size: 25px;
  }

  #datamanagement p,
  b {
    font-size: 20px;
  }

  #datamanagement ul li {
    font-size: 15px;
  }

  #datamanagement img {
    width: 50%;
    height: 200px;
  }

  #datamanagementblock {
    display: flex;
    flex-direction: row;
  }

  #datamanagementcontent {
    width: 50%;
  }

  #datamanagementimg {
    width: 50%;
  }

  /* Enterprise Data Warehouse */
  #enterprisedata h1 {
    font-size: 30px;
  }

  #enterprisedata h2 {
    font-size: 25px;
  }

  #enterprisedata p,
  b {
    font-size: 20px;
  }

  #enterprisedata ul li {
    font-size: 15px;
  }

  #enterprisedata img {
    width: 100%;
    height: 200px;
  }

  #enterprisedatablock {
    display: flex;
    flex-direction: row;
  }

  #enterprisedatacontent {
    width: 50%;
  }

  #enterprisedataimg {
    width: 50%;
  }


  /* Services */
  /* Business Intelligence Services */
  #businessIntelligence h1 {
    font-size: 30px;
  }

  #businessIntelligence h2 {
    font-size: 25px;
  }

  #businessIntelligence h4 {
    font-size: 20px;
  }

  #businessIntelligence p,
  b {
    font-size: 15px;
  }

  #businessIntelligence ul li {
    font-size: 10px;
  }

  /* Enterprise Mobility Service */
  #enterprisemobility h1 {
    font-size: 30px;
  }

  #enterprisemobility p {
    font-size: 20px;
  }

  #enterprisemobility ul li {
    font-size: 15px;
  }

  /* Mobile Apps */
  #mobileapps h1 {
    font-size: 30px;
  }

  #mobileapps p {
    font-size: 20px;
  }

  #mobileapps ul li {
    font-size: 15px;
  }

  /* Testing And QA Services */
  #testingandqa h1 {
    font-size: 30px;
  }

  #testingandqa p {
    font-size: 20px;
  }

  #testingandqa ul li {
    font-size: 15px;
  }

  /* Web Application */
  #webapplication h1 {
    font-size: 30px;
  }

  #webapplication p {
    font-size: 20px;
  }

  #webapplication ul li {
    font-size: 15px;
  }


  /* Enterprise Mobility */
  /* Business Process */
  #businessprocess h1 {
    font-size: 30px;
  }

  #businessprocess p {
    font-size: 15px;
  }

  /* B2B Mobile */
  #b2bmobile h1 {
    font-size: 30px;
  }

  #b2bmobile h2 {
    font-size: 25px;
  }

  #b2bmobile p {
    font-size: 20px;
  }

  #b2bmobile ul li {
    font-size: 15px;
  }

  /* Android App */
  #enterpriseandroid h1 {
    font-size: 30px;
  }

  #enterpriseandroid p {
    font-size: 20px;
  }

  #enterpriseandroid ul li {
    font-size: 15px;
  }

  /* Enterprise APP */
  #enterpriseapp h1 {
    font-size: 30px;
  }

  #enterpriseapp h3 {
    font-size: 25px;
  }

  #enterpriseapp p {
    font-size: 20px;
  }

  /* Enterprise IOS App */
  #enterpriseiosapp h1 {
    font-size: 30px;
  }

  #enterpriseiosapp h3 {
    font-size: 25px;
  }

  #enterpriseiosapp p {
    font-size: 20px;
  }

  /* Mobile Business */
  #mobilebusiness h1 {
    font-size: 30px;
  }

  #mobilebusiness p {
    font-size: 20px;
  }

  /* Mobile Apps */
  /* Android App */
  #androidapp h1 {
    font-size: 30px;
  }

  #androidapp p {
    font-size: 20px;
  }

  /* Blackberry App */
  #blackberryapp h1 {
    font-size: 30px;
  }

  #blackberryapp p {
    font-size: 20px;
  }

  /* Iphone App */
  #iphoneapp h1 {
    font-size: 30px;
  }

  #iphoneapp p {
    font-size: 20px;
  }

  /* Windows8 App */
  #windows8app h1 {
    font-size: 30px;
  }

  #windows8app p {
    font-size: 20px;
  }


  /* Testing & QA Services*/
  /* Automated Testing */
  #automatedtesting h1 {
    font-size: 30px;
  }

  #automatedtesting h3 {
    font-size: 25px;
  }

  #automatedtesting p {
    font-size: 20px;
  }

  #automatedtesting ul li {
    font-size: 15px;
  }

  /* Manual Testing */
  #manualtesting h1 {
    font-size: 30px;
  }

  #manualtesting h3 {
    font-size: 25px;
  }

  #manualtesting p {
    font-size: 20px;
  }

  #manualtesting ul li {
    font-size: 15px;
  }

  /* QTP */
  #qtp h1 {
    font-size: 30px;
  }

  #qtp h3 {
    font-size: 25px;
  }

  #qtp p {
    font-size: 20px;
  }

  #qtp ul li {
    font-size: 15px;
  }

  /* Selenium */
  #selenium h1 {
    font-size: 30px;
  }

  #selenium h3 {
    font-size: 25px;
  }

  #selenium p {
    font-size: 20px;
  }

  #selenium ul li {
    font-size: 15px;
  }

  /* Test Complete */
  #testcomplete h1 {
    font-size: 30px;
  }

  #testcomplete h3 {
    font-size: 25px;
  }

  #testcomplete p {
    font-size: 20px;
  }

  #testcomplete ul li {
    font-size: 15px;
  }

  /* Web Application */
  /* CRM Application */
  #cmrapplication h1 {
    font-size: 30px;
  }

  #cmrapplication p {
    font-size: 20px;
  }

  /* Content Management */
  #contentmanagement h1 {
    font-size: 30px;
  }

  #contentmanagement h3 {
    font-size: 25px;
  }

  #contentmanagement p {
    font-size: 20px;
  }

  #contentmanagement ul li {
    font-size: 15px;
  }

  /* Custom Web App */
  #customwebapp h1 {
    font-size: 30px;
  }

  #customwebapp p {
    font-size: 20px;
  }

  #customwebapp ul li {
    font-size: 15px;
  }

  /* Inventory Management */
  #inventorymanagement h1 {
    font-size: 30px;
  }

  #inventorymanagement h3 {
    font-size: 25px;
  }

  #inventorymanagement p {
    font-size: 20px;
  }

  #inventorymanagement ul li {
    font-size: 15px;
  }

  /* Open Source */
  #opensource h1 {
    font-size: 30px;
  }

  #opensource p {
    font-size: 20px;
  }
}

/* For Tablet */
@media (min-width: 481px) and (max-width: 768px) {
  /*(min-width: 481px) and (max-width: 768px)*/

  /* Navbar */
  #navbar1 {
    display: none;
  }

  #navbar2 {
    display: block;
  }

  #navbar2 #logo h5 {
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
  }

  #navbar2 .offcanvas-body {
    margin-top: -50px;
    text-align: start;
  }

  #navbar2 .offcanvas-body ul li a {
    color: white;
  }

  #navbar2 .offcanvas-body ul li a:hover {
    color: rgb(255, 7, 255);
  }

  /* Enquiry Form */
  #eqform {
    height: 800px;
    background-image: url('/public/images/enquiry.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  #eqnform {
    margin-top: 75px;
    margin-left: 200px;
    padding: 50px;
  }

  #eqnform form {
    width: 75%;
  }


  /* Footer */
  #footercontentblock1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }

  #footercontentblock2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footercontent1 {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
    text-align: start;
  }

  #footer1 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footer1 h3 {
    font-size: 20px;
    margin-left: -70px;
  }

  #footer1 .links {
    font-size: 10px;
    margin-left: -25px;
  }

  #footer2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footer2 h3 {
    font-size: 20px;
    margin-left: -70px;
  }

  #footer2 .links {
    font-size: 10px;
    margin-left: -50px;
  }

  #divide2 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
  }

  #footer3 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footer3 h3 {
    font-size: 20px;
  }

  #footer3 .links {
    font-size: 10px;
    margin-left: -25px;
  }

  #footer4 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footer4 h3 {
    font-size: 20px;
  }

  #footer4 .links {
    font-size: 10px;
    margin-left: -25px;
  }

  #divide4 {
    width: auto;
    height: 1px;
    border: 1px solid grey;
  }

  #footertext p {
    font-size: 11.5px;
  }

  #footertext {
    display: flex;
    flex-direction: column-reverse;
  }

  #footertext p {
    margin-top: 10px;
    font-size: 11px;
    margin-bottom: 0px;
  }

  #footertext span a {
    font-size: 10px;
    text-decoration: none;
    color: white;
  }

  #footertext span a:hover {
    color: rgb(8, 131, 247);
    text-decoration: underline;
  }

  /* Terms and conditions */
  #terms {
    margin: 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #terms h3 {
    color: white;
    font-size: 18px;
    font-weight: bolder;
  }

  #terms h2 {
    color: white;
    font-size: 25px;
    font-weight: bolder;
  }

  #terms p {
    font-size: 14px;
    color: white;
  }

  #terms ul li {
    font-size: 14px;
    color: white;
  }

  /* Privacy Policy */
  #privacy {
    margin: 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #privacy h2 {
    color: white;
    font-size: 25px;
    font-weight: bolder;
  }

  #privacy h3 {
    color: white;
    font-size: 18px;
    font-weight: bolder;
  }

  #privacy p {
    color: white;
    font-size: 14px;
  }

  #privacy ul li {
    color: white;
    font-size: 14px;
  }

  #indialocation {
    margin-left: 75px;
  }

  /* Home Page */
  /* carousel */
  #carouselExampleInterval {
    width: auto;
    /* height: 300px; */
    margin-left: 40px;
    margin-top: -75px;
    margin-right: 45px;
  }

  .carousel-item {
    top: 90px;
    height: 450px;
  }

  .carousel-caption {
    width: auto;
    height: 200px;
    /* margin-top: 20px; */
  }

  .carousel-caption p {
    font-size: 15px;
    font-weight: bold;
    color: white;
    /* margin-top: -450px; */
    margin-left: 200px;
  }

  #carousel1 .carousel-caption p {
    font-size: 30px;
    margin-top: -50px;
    margin-left: -25px;
  }

  #carousel2 .carousel-caption p {
    font-size: 30px;
    margin-top: 0px;
    margin-left: 0px;
  }

  #carousel3 .carousel-caption p {
    font-size: 30px;
    margin-top: 0px;
    margin-left: 25px;
  }

  /* About */
  #section1 h1 {
    width: auto;
    font-size: 30px;
  }

  #section1 h3 {
    width: auto;
    font-size: 20px;
  }

  #section1 p {
    width: auto;
    font-size: 14px;
  }

  #aboutusblock1 {
    display: flex;
    flex-direction: row;
  }

  #aboutuscontent1 {
    width: 50%;
    text-align: start;
  }

  #aboutusbtn1 {
    width: 100px;
    padding: 10px;
  }

  #aboutusimg1 {
    width: 50%;
  }

  #aboutusimg1 video {
    width: 100%;
    height: 200px;
  }

  #aboutusimg1 img {
    width: 300px;
    height: 300px;
  }

  /* Corporate */
  #section21 {
    height: auto;
    padding-right: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #corporateblock .cards1 {
    display: flex;
  }

  #corporateblock .cards1 .text-cards1 {
    width: 250px;
    height: 300px;
    background-color: rgb(7, 230, 7);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards1 .text-cards1 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #corporateblock .cards2 {
    display: flex;
  }

  #corporateblock .cards2 .text-cards2 {
    width: 250px;
    height: 300px;
    background-color: rgb(245, 10, 233);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards2 .text-cards2 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #corporateblock .cards3 {
    display: flex;
  }

  #corporateblock .cards3 .text-cards3 {
    width: 250px;
    height: 300px;
    background-color: rgb(230, 178, 7);
    margin: 10px;
    padding: 15px;
  }

  #corporateblock .cards3 .text-cards3 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #section2 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section2 p {
    width: 100%;
    font-size: 20px;
  }

  #section2 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section3 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section3 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section3 p {
    width: 100%;
    font-size: 20px;
  }

  #section4 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section4 p {
    width: 100%;
    font-size: 20px;
  }

  #section4 #bigdata img {
    width: 200px;
    height: 200px;
  }

  #section5 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section5 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section5 p {
    width: 100%;
    font-size: 20px;
  }

  #section6 h1 {
    width: 100%;
    font-size: 30px;
  }

  #section6 p {
    width: 100%;
    font-size: 15px;
  }


  /* Solutions */
  #section7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section8 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section9 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #solutions section .cards12 {
    display: flex;
  }

  #solutions section .cards12 .text-cards12 {
    width: 250px;
    height: 250px;
    background-color: rgb(5, 122, 255);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards12 .text-cards12 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  #solutions section .cards13 {
    display: flex;
  }

  #solutions section .cards13 .text-cards13 {
    width: 250px;
    height: 250px;
    background-color: rgb(247, 151, 8);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards13 .text-cards13 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Third section */
  #solutions section .cards14 {
    display: flex;
  }

  #solutions section .cards14 .text-cards14 {
    width: 250px;
    height: 250px;
    background-color: rgb(196, 11, 165);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards14 .text-cards14 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fourth section */
  #solutions section .cards15 {
    display: flex;
  }

  #solutions section .cards15 .text-cards15 {
    width: 250px;
    height: 250px;
    background-color: rgb(10, 238, 181);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards15 .text-cards15 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fifth section */
  #solutions section .cards16 {
    display: flex;
  }

  #solutions section .cards16 .text-cards16 {
    width: 250px;
    height: 250px;
    background-color: rgb(248, 244, 7);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards16 .text-cards16 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }


  /* Our services */
  #section31 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section32 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section33 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards1 {
    display: flex;
  }

  section .cards1 .text-cards1 {
    width: 250px;
    height: 250px;
    background-color: rgb(7, 185, 230);
    margin: 10px;
    padding: 15px;
  }

  section .cards1 .text-cards1 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  section .cards2 {
    display: flex;
  }

  section .cards2 .text-cards2 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards2 .text-cards2 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Third section */
  section .cards3 {
    display: flex;
  }

  section .cards3 .text-cards3 {
    width: 250px;
    height: 250px;
    background-color: rgb(252, 4, 157);
    margin: 10px;
    padding: 15px;
  }

  section .cards3 .text-cards3 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Fourth section */
  section .cards4 {
    display: flex;
  }

  section .cards4 .text-cards4 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards4 .text-cards4 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Fifth section */
  section .cards5 {
    display: flex;
  }

  section .cards5 .text-cards5 {
    width: 250px;
    height: 250px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards5 .text-cards5 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }


  /* Industries */
  #section41 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section42 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section43 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards6 {
    display: flex;
  }

  section .cards6 .text-cards6 {
    width: 250px;
    height: 250px;
    background-color: rgb(250, 7, 68);
    margin: 10px;
    padding: 15px;
  }

  section .cards6 .text-cards6 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  section .cards7 {
    display: flex;
  }

  section .cards7 .text-cards7 {
    width: 250px;
    height: 250px;
    background-color: rgb(46, 245, 7);
    margin: 10px;
    padding: 15px;
  }

  section .cards7 .text-cards7 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Third section */
  section .cards8 {
    display: flex;
  }

  section .cards8 .text-cards8 {
    width: 250px;
    height: 250px;
    background-color: rgb(173, 4, 252);
    margin: 10px;
    padding: 15px;
  }

  section .cards8 .text-cards8 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fourth section */
  section .cards9 {
    display: flex;
  }

  section .cards9 .text-cards9 {
    width: 250px;
    height: 250px;
    background-color: rgb(248, 5, 187);
    margin: 10px;
    padding: 15px;
  }

  section .cards9 .text-cards9 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fifth section */
  section .cards10 {
    display: flex;
  }

  section .cards10 .text-cards10 {
    width: 250px;
    height: 250px;
    background-color: rgb(238, 118, 5);
    margin: 10px;
    padding: 15px;
  }

  section .cards10 .text-cards10 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Sixth section */
  section .cards11 {
    display: flex;
  }

  section .cards11 .text-cards11 {
    width: 250px;
    height: 250px;
    background-color: rgb(11, 193, 238);
    margin: 10px;
    padding: 15px;
  }

  section .cards11 .text-cards11 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }


  #section10 h1 {
    font-size: 30px;
  }

  #section11 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section11 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section11 p {
    width: 100%;
    font-size: 20px;
  }

  #section12 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section12 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section12 p {
    width: 100%;
    font-size: 20px;
  }

  #section13 h2 {
    width: auto;
    font-size: 30px;
  }

  #section13 p {
    width: auto;
    font-size: 20px;
  }


  /* Aboutus */
  #aboutusblock {
    display: flex;
    flex-direction: column-reverse;
  }

  #aboutuscontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
  }

  #aboutusimage {
    width: 100%;
    padding-right: 45px;
    padding-left: 40px;
    margin-bottom: 15px;
  }

  #aboutusimage img {
    width: 70%;
    height: 300px;
  }

  #aboutuscontent p {
    width: 100%;
    font-size: 13px;
  }

  /* Vision & Mission */
  #visionandmission h1 {
    font-size: 20px;
  }

  #visionandmission h4 {
    font-size: 17px;
  }

  #visionandmission p {
    font-size: 13px;
  }

  #visionandmission img {
    width: 80%;
    height: 100px;
  }

  /* Core value */
  #corevalue h1 {
    font-size: 20px;
  }

  #corevalue h3 {
    font-size: 17px;
    text-align: start;
  }

  #corevalue ul li {
    font-size: 13px;
  }

  /* Social Responsibility */
  #socialresponsibilityblock {
    display: flex;
    flex-direction: column;
  }

  #socialresponsibilitycontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
  }

  #socialresponsibilityimg {
    width: 100%;
    padding-left: 45px;
    padding-right: 50px;
    margin-bottom: 15px;
  }

  #socialresponsibilityimg img {
    width: 70%;
    height: 200px;
  }

  #socialresponsibility h1 {
    font-size: 20px;
  }

  #socialresponsibility h2 {
    font-size: 17px;
  }

  #socialresponsibility p {
    font-size: 13px;
  }

  /* Location */
  #location h1 {
    font-size: 30px;
  }

  #location h2 {
    font-size: 25px;
  }

  #location h3 {
    font-size: 20px;
  }

  #location p {
    font-size: 15px;
  }

  #locationbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #locationbtn #btn1 {
    width: 150px;
    font-size: 25px;
    margin-right: 25px;
  }

  #locationbtn #btn2 {
    width: 150px;
    font-size: 25px;
  }

  #malaysiablock{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  #locationblock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  #indialocation{
    width: 500px;
    margin-left: -10px;
  }
  #vizaglocation{
    width: 500px;
  }
  #malaysiablock #malaysialocation{
    width: 500px;
  }

  #locationblock #indialocation img {
    height: 300px;
  }

  #locationblock #vizaglocation img {
    height: 300px;
  }

  #malaysialocation img {
    height: 300px;
  }

  /* Teams */
  #teams {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  #teamsblock1 {
    margin-right: 50px;
  }

  #teamsblock1 .card {
    width: 300px;
    height: 350px;
    border: none;
  }

  #teamsblock1 .card img {
    width: 100%;
    height: 250px;
  }

  #teamsblock1 .card-body span {
    font-size: 20px;
    font-weight: bold;
  }

  #teamsblock1 .card-body p {
    font-size: 20px;
  }

  #teamsblock2 .card {
    width: 300px;
    height: 350px;
    border: none;
  }

  #teamsblock2 .card img {
    width: 100%;
    height: 250px;
  }

  #teamsblock2 .card-body span {
    font-size: 20px;
    font-weight: bold;
  }

  #teamsblock2 .card-body p {
    font-size: 20px;
  }


  #teamsblock1 .modal-content {
    width: 600px;
    height: 600px;
    left: -50px;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock1 .modal-body {
    width: 40%;
  }

  #teamsblock1 .modal-body img {
    width: 200px;
    height: 200px;
    margin-top: 150px;
  }

  #teamsblock1 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px;
    overflow: scroll;
  }

  #teamsblock1 .modal-title {
    font-size: 18px;
    margin-right: 75px;
  }

  #teamsblock1 .modal-text {
    font-size: 16px;
  }

  #teamsblock1 .modal-header .text {
    font-size: 15px;
    text-align: justify;
  }

  #teamsblock2 .modal-content {
    width: 600px;
    height: 600px;
    left: -50px;
    top: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock2 .modal-body {
    width: 40%;
  }

  #teamsblock2 .modal-body img {
    width: 200px;
    height: 200px;
    margin-top: 150px;
  }

  #teamsblock2 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px;
    overflow: scroll;
  }

  #teamsblock2 .modal-title {
    font-size: 18px;
    margin-right: 125px;
  }

  #teamsblock2 .modal-text {
    font-size: 16px;
  }

  #teamsblock2 .modal-header .text {
    font-size: 16px;
    text-align: justify;
  }


  /* Industries */
  /* Financial Services */
  #financialservice h1 {
    font-size: 25px;
  }

  #financialservice h4 {
    font-size: 18px;
  }

  #financialservice p {
    font-size: 14px;
  }

  #financialservice img {
    width: 70%;
    height: 200px;
  }

  #financialserviceblock {
    display: flex;
    flex-direction: column-reverse;
  }

  #financialservicecontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
  }

  #financialserviceimg {
    width: 100%;
    padding-right: 50px;
    padding-left: 45px;
    margin-bottom: 15px;
  }

  /* Government */
  #government h1 {
    font-size: 25px;
  }

  #government h3 {
    font-size: 18px;
  }

  #government p {
    font-size: 14px;
  }

  #government ul li {
    font-size: 14px;
  }

  /* Manufacturing */
  #manufacturing h1 {
    font-size: 25px;
  }

  #manufacturing p {
    font-size: 14px;
  }

  #manufacturing ul li {
    font-size: 14px;
  }

  /* Retail */
  #retail h1 {
    font-size: 25px;
  }

  #retail img {
    width: 70%;
    height: 200px;
  }

  #retail p {
    font-size: 14px;
  }

  #retail ul li {
    font-size: 14px;
    text-align: start;
  }

  #retail #img {
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
  }

  #retail #retailcontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    text-align: justify;
  }

  #retailblock {
    display: flex;
    flex-direction: column;
  }

  /* Telecommunication */
  #telecommunication h1 {
    font-size: 25px;
  }

  #telecommunication h4 {
    font-size: 18px;
  }

  #telecommunication p {
    font-size: 14px;
  }

  #telecommunication ul li {
    font-size: 14px;
    text-align: start;
  }

  #telecommunication img {
    width: 70%;
    height: 200px;
  }

  #telecommunicationblock {
    display: flex;
    flex-direction: column-reverse;
  }

  #telecommunicationcontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
  }

  #telecommunicationimg {
    width: 100%;
    padding-right: 45px;
    padding-left: 45px;
  }

  /* Utilities */
  #utilities h1 {
    font-size: 25px;
  }

  #utilities h3 {
    font-size: 18px;
  }

  #utilities p {
    font-size: 14px;
  }

  #utilities ul li {
    font-size: 14px;
  }

  /* Products */
  /* Log-Mode */
  #logmode h1 {
    font-size: 25px;
  }

  #logmode h4 {
    font-size: 20px;
  }

  #logmode h5 {
    font-size: 18px;
  }

  #logmode p {
    font-size: 14px;
  }

  #logmode img {
    width: 75%;
    height: 200px;
  }

  #logmodeblock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
  }

  /* Solutions */
  /* Application Middleware */
  #applicationmiddleware h1 {
    font-size: 25px;
  }

  #applicationmiddleware h3 {
    font-size: 18px;
  }

  #applicationmiddleware p {
    font-size: 14px;
  }

  #applicationmiddleware ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Big Data */
  #bigdata h1 {
    font-size: 25px;
  }

  #bigdata h2 {
    font-size: 18px;
  }

  #bigdata p,
  b {
    font-size: 14px;
  }

  #bigdata ul li {
    font-size: 14px;
  }

  #bigdata img {
    width: 75%;
    height: 200px;
  }

  /* Business Intelligence Solutions */
  #businessintelligence h1 {
    font-size: 25px;
  }

  #businessintelligence h3 {
    font-size: 18px;
  }

  #businessintelligence p {
    font-size: 14px;
    text-align: justify;
  }

  #businessintelligence b {
    font-size: 16px;
  }

  #businessintelligence ul li {
    font-size: 14px;
    text-align: start;
  }

  #businessintelligenceblock1 {
    display: flex;
    flex-direction: column;
  }

  #businessintelligenceblock1 p {
    width: 100%;
  }

  #businessintelligenceblock1 img {
    width: 75%;
    height: 200px;
    margin-bottom: 10px;
  }

  #businessintelligenceblock2 {
    display: flex;
    flex-direction: column;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 img {
    width: 100%;
    height: 250px;
  }

  /* Data Management */
  #datamanagement h1 {
    font-size: 25px;
  }

  #datamanagement h3 {
    font-size: 18px;
  }

  #datamanagement p {
    font-size: 14px;
  }

  #datamanagement b {
    font-size: 16px;
  }

  #datamanagement ul li {
    font-size: 14px;
  }

  #datamanagement img {
    width: 75%;
    height: 250px;
  }

  #datamanagementblock {
    display: flex;
    flex-direction: column;
  }

  #datamanagementcontent {
    width: 100%;
  }

  #datamanagementimg {
    width: 100%;
  }

  /* Enterprise Data Warehouse */
  #enterprisedata h1 {
    font-size: 25px;
  }

  #enterprisedata h2 {
    font-size: 18px;
  }

  #enterprisedata p {
    font-size: 14px;
  }

  #enterprisedata b {
    font-size: 16px;
  }

  #enterprisedata ul li {
    font-size: 14px;
    text-align: start;
  }

  #enterprisedata img {
    width: 100%;
    height: 250px;
  }

  #enterprisedatablock {
    display: flex;
    flex-direction: column;
  }

  #enterprisedatacontent {
    width: 100%;
  }

  #enterprisedataimg {
    width: 100%;
  }


  /* Services */
  /* Business Intelligence Services */
  #businessIntelligence h1 {
    font-size: 25px;
  }

  #businessIntelligence h2 {
    font-size: 20px;
  }

  #businessIntelligence h4 {
    font-size: 18px;
  }

  #businessIntelligence p,
  b {
    font-size: 14px;
  }

  #businessIntelligence ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Enterprise Mobility Service */
  #enterprisemobility h1 {
    font-size: 25px;
  }

  #enterprisemobility p {
    font-size: 14px;
  }

  #enterprisemobility ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Mobile Apps */
  #mobileapps h1 {
    font-size: 25px;
  }

  #mobileapps p {
    font-size: 14px;
  }

  #mobileapps ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Testing And QA Services */
  #testingandqa h1 {
    font-size: 25px;
  }

  #testingandqa p {
    font-size: 14px;
  }

  #testingandqa ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Web Application */
  #webapplication h1 {
    font-size: 25px;
  }

  #webapplication p {
    font-size: 14px;
  }

  #webapplication ul li {
    font-size: 14px;
    text-align: start;
  }


  /* Enterprise Mobility */
  /* Business Process */
  #businessprocess h1 {
    font-size: 25px;
  }

  #businessprocess p {
    font-size: 14px;
  }

  /* B2B Mobile */
  #b2bmobile h1 {
    font-size: 25px;
  }

  #b2bmobile h2 {
    font-size: 18px;
  }

  #b2bmobile p {
    font-size: 14px;
  }

  #b2bmobile ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Android App */
  #enterpriseandroid h1 {
    font-size: 25px;
  }

  #enterpriseandroid p {
    font-size: 14px;
  }

  #enterpriseandroid ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Enterprise APP */
  #enterpriseapp h1 {
    font-size: 25px;
  }

  #enterpriseapp h3 {
    font-size: 18px;
  }

  #enterpriseapp p {
    font-size: 14px;
  }

  /* Enterprise IOS App */
  #enterpriseiosapp h1 {
    font-size: 25px;
  }

  #enterpriseiosapp h3 {
    font-size: 18px;
  }

  #enterpriseiosapp p {
    font-size: 14px;
  }

  /* Mobile Business */
  #mobilebusiness h1 {
    font-size: 25px;
  }

  #mobilebusiness p {
    font-size: 14px;
  }

  /* Mobile Apps */
  /* Android App */
  #androidapp h1 {
    font-size: 25px;
  }

  #androidapp p {
    font-size: 14px;
  }

  /* Blackberry App */
  #blackberryapp h1 {
    font-size: 25px;
  }

  #blackberryapp p {
    font-size: 14px;
  }

  /* Iphone App */
  #iphoneapp h1 {
    font-size: 25px;
  }

  #iphoneapp p {
    font-size: 14px;
  }

  /* Windows8 App */
  #windows8app h1 {
    font-size: 25px;
  }

  #windows8app p {
    font-size: 14px;
  }


  /* Testing & QA Services*/
  /* Automated Testing */
  #automatedtesting h1 {
    font-size: 25px;
  }

  #automatedtesting h3 {
    font-size: 18px;
  }

  #automatedtesting p {
    font-size: 14px;
  }

  #automatedtesting ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Manual Testing */
  #manualtesting h1 {
    font-size: 25px;
  }

  #manualtesting h3 {
    font-size: 18px;
  }

  #manualtesting p {
    font-size: 14px;
  }

  #manualtesting ul li {
    font-size: 14px;
    text-align: start;
  }

  /* QTP */
  #qtp h1 {
    font-size: 25px;
  }

  #qtp h3 {
    font-size: 18px;
  }

  #qtp p {
    font-size: 14px;
  }

  #qtp ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Selenium */
  #selenium h1 {
    font-size: 25px;
  }

  #selenium h3 {
    font-size: 18px;
  }

  #selenium p {
    font-size: 14px;
  }

  #selenium ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Test Complete */
  #testcomplete h1 {
    font-size: 25px;
  }

  #testcomplete h3 {
    font-size: 18px;
  }

  #testcomplete p {
    font-size: 14px;
  }

  #testcomplete ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Web Application */
  /* CRM Application */
  #cmrapplication h1 {
    font-size: 25px;
  }

  #cmrapplication p {
    font-size: 14px;
  }

  /* Content Management */
  #contentmanagement h1 {
    font-size: 25px;
  }

  #contentmanagement h3 {
    font-size: 18px;
  }

  #contentmanagement p {
    font-size: 14px;
  }

  #contentmanagement ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Custom Web App */
  #customwebapp h1 {
    font-size: 25px;
  }

  #customwebapp p {
    font-size: 14px;
  }

  #customwebapp ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Inventory Management */
  #inventorymanagement h1 {
    font-size: 25px;
  }

  #inventorymanagement h3 {
    font-size: 18px;
  }

  #inventorymanagement p {
    font-size: 14px;
  }

  #inventorymanagement ul li {
    font-size: 14px;
    text-align: start;
  }

  /* Open Source */
  #opensource h1 {
    font-size: 25px;
  }

  #opensource p {
    font-size: 14px;
  }
}

/* For Mobiles */
@media (min-width: 320px) and (max-width: 480px) {
  /*(min-width: 320px) and (max-width: 480px)*/

  /* Navbar */
  nav {
    position: sticky;
    top: 0;
  }

  #navbar1 {
    display: none;
  }

  #navbar2 {
    display: block;
  }

  #navbar2 #logo h5 {
    margin-left: 5px;
    font-size: 12.5px;
    font-weight: bold;
  }

  #navbar2 .offcanvas-body {
    margin-top: -50px;
    text-align: start;
  }

  #navbar2 .offcanvas-body ul li a {
    color: white;
  }

  #navbar2 .offcanvas-body ul li a:hover {
    color: rgb(255, 7, 255);
  }

  #navbar2 .navbar-toggler {
    position: absolute;
    top: 10%;
    right: 5%
  }

  /* Enquiry Form */
  #eqform {
    height: 800px;
    background-image: url('/public/images/enquiry.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  #eqnform {
    margin-top: 75px;
    margin-left: -10px;
  }

  #eqnform form {
    width: 100%;
  }

  /* Footer */
  #footercontentblock1 {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }

  #footercontentblock2 {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #footercontent1 {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 20px;
    text-align: start;
  }

  #footer1 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer1 h3 {
    font-size: 15px;
    text-align: start;
    margin-left: 5px;
  }

  #footer1 .links {
    font-size: 8px;
    margin-left: -25px;
  }

  #footer2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer2 h3 {
    font-size: 15px;
    text-align: start;
    margin-left: 5px;
  }

  #footer2 .links {
    font-size: 8px;
    margin-left: -25px;
  }

  #divide2 {
    width: 1px;
    height: auto;
    border: 1px solid grey;
    margin-right: 25px;
  }

  #footer3 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer3 h3 {
    font-size: 15px;
    text-align: start;
    margin-left: 5px;
    margin-top: 10px;
  }

  #footer3 .links {
    font-size: 8px;
    margin-left: -27px;
  }

  #footer4 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #footer4 h3 {
    font-size: 15px;
    text-align: start;
    margin-left: 5px;
  }

  #footer4 .links {
    font-size: 8px;
    margin-left: -25px;
  }

  #divide4 {
    width: auto;
    height: 1px;
    border: 1px solid grey;
  }

  #footertext p {
    font-size: 11.5px;
  }

  #footertext {
    display: flex;
    flex-direction: column-reverse;
  }

  #footertext p {
    margin-top: 10px;
    font-size: 11px;
    margin-bottom: 0px;
  }

  #footertext span a {
    font-size: 10px;
    text-decoration: none;
    color: white;
  }

  #footertext span a:hover {
    color: rgb(8, 131, 247);
    text-decoration: underline;
  }

  /* Terms and conditions */
  #termscontent h1 {
    font-size: 25px;
  }

  #terms {
    padding: 25px 50px;
    margin-bottom: -16px;
    text-align: justify;
  }

  #terms h3 {
    color: white;
    font-size: 18px;
    font-weight: bolder;
  }

  #terms h2 {
    color: white;
    font-size: 20px;
    font-weight: bolder;
  }

  #terms p {
    font-size: 9px;
    color: white;
  }

  #terms ul li {
    font-size: 9px;
    color: white;
    text-align: start;
  }

  /* Privacy Policy */
  #privacycontent h1 {
    font-size: 25px;
  }

  #privacy {
    margin: 25px;
    margin-bottom: -16px;
    text-align: justify;
    padding: 25px;
    padding-top: 0px;
  }

  #privacy h2 {
    color: white;
    font-size: 20px;
    font-weight: bolder;
  }

  #privacy h3 {
    color: white;
    font-size: 18px;
    font-weight: bolder;
  }

  #privacy p {
    color: white;
    font-size: 9px;
  }

  #privacy ul li {
    color: white;
    font-size: 9px;
    text-align: start;
  }

  #indialocation {
    margin-left: 75px;
  }

  /* Home Page */
  /* carousel */
  #carouselExampleInterval {
    width: auto;
    margin-left: 40px;
    margin-top: -75px;
    margin-right: 45px;
  }

  .carousel-item {
    /* top: 90px; */
    width: auto;
    height: 250px;
  }

  #carousel1 .carousel-caption p {
    font-size: 12.5px;
    margin-left: -10px;
  }

  #carousel2 .carousel-caption p {
    font-size: 12.5px;
    margin-top: 50px;
    margin-left: 25px;
  }

  #carousel3 .carousel-caption p {
    font-size: 12.5px;
    margin-top: 50px;
    margin-left: 25px;
  }

  .carousel-control-prev {
    margin-top: 100px;
  }

  .carousel-control-next {
    margin-top: 100px;
  }

  /* About */
  #section1 h1 {
    width: auto;
    font-size: 20px;
  }

  #section1 p {
    width: auto;
    font-size: 9px;
  }

  #aboutusblock1 {
    display: flex;
    flex-direction: column;
  }

  #aboutuscontent1 {
    width: 100%;
  }

  #aboutusbtn1 {
    width: 100px;
    padding: 10px;
  }

  #aboutusimg1 {
    width: 100%;
    margin: 20px 0px;
  }

  #aboutusimg1 video {
    width: 100%;
    height: 150px;
  }

  #aboutusimg1 img {
    width: 100%;
    height: 200px;
  }

  /* Corporate */
  #section21 {
    height: auto;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #corporateblock .cards1 {
    display: flex;
  }

  #corporateblock .cards1 .text-cards1 {
    width: 225px;
    height: 325px;
    background-color: rgb(7, 230, 7);
    margin: 10px;
    padding: 10px;
  }

  #corporateblock .cards1 .text-cards1 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #corporateblock .cards2 {
    display: flex;
  }

  #corporateblock .cards2 .text-cards2 {
    width: 225px;
    height: 325px;
    background-color: rgb(245, 10, 233);
    margin: 10px;
    padding: 10px;
  }

  #corporateblock .cards2 .text-cards2 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #corporateblock .cards3 {
    display: flex;
  }

  #corporateblock .cards3 .text-cards3 {
    width: 225px;
    height: 325px;
    background-color: rgb(230, 178, 7);
    margin: 10px;
    padding: 10px;
  }

  #corporateblock .cards3 .text-cards3 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  #section2 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section2 p {
    width: 100%;
    font-size: 20px;
  }

  #section2 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section3 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section3 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section3 p {
    width: 100%;
    font-size: 20px;
  }

  #section4 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section4 p {
    width: 100%;
    font-size: 20px;
  }

  #section4 #bigdata img {
    width: 200px;
    height: 200px;
  }

  #section5 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section5 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section5 p {
    width: 100%;
    font-size: 20px;
  }

  #section6 h1 {
    width: 100%;
    font-size: 30px;
  }

  #section6 p {
    width: 100%;
    font-size: 15px;
  }


  /* Solutions */
  #section7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #solutions section .cards12 {
    display: flex;
  }

  #solutions section .cards12 .text-cards12 {
    width: 225px;
    height: 275px;
    background-color: rgb(5, 122, 255);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards12 .text-cards12 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  #solutions section .cards13 {
    display: flex;
  }

  #solutions section .cards13 .text-cards13 {
    width: 225px;
    height: 275px;
    background-color: rgb(247, 151, 8);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards13 .text-cards13 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Third section */
  #solutions section .cards14 {
    display: flex;
  }

  #solutions section .cards14 .text-cards14 {
    width: 225px;
    height: 275px;
    background-color: rgb(196, 11, 165);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards14 .text-cards14 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fourth section */
  #solutions section .cards15 {
    display: flex;
  }

  #solutions section .cards15 .text-cards15 {
    width: 225px;
    height: 275px;
    background-color: rgb(10, 238, 181);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards15 .text-cards15 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fifth section */
  #solutions section .cards16 {
    display: flex;
  }

  #solutions section .cards16 .text-cards16 {
    width: 225px;
    height: 275px;
    background-color: rgb(248, 244, 7);
    margin: 10px;
    padding: 15px;
  }

  #solutions section .cards16 .text-cards16 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }


  /* Our services */
  #section31 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section32 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section33 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 50px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards1 {
    display: flex;
  }

  section .cards1 .text-cards1 {
    width: 225px;
    height: 300px;
    background-color: rgb(7, 185, 230);
    margin: 10px;
    padding: 15px;
  }

  section .cards1 .text-cards1 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  section .cards2 {
    display: flex;
  }

  section .cards2 .text-cards2 {
    width: 225px;
    height: 300px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards2 .text-cards2 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Third section */
  section .cards3 {
    display: flex;
  }

  section .cards3 .text-cards3 {
    width: 225px;
    height: 300px;
    background-color: rgb(252, 4, 157);
    margin: 10px;
    padding: 15px;
  }

  section .cards3 .text-cards3 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Fourth section */
  section .cards4 {
    display: flex;
  }

  section .cards4 .text-cards4 {
    width: 225px;
    height: 300px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards4 .text-cards4 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  /* Fifth section */
  section .cards5 {
    display: flex;
  }

  section .cards5 .text-cards5 {
    width: 225px;
    height: 325px;
    background-color: rgb(132, 139, 240);
    margin: 10px;
    padding: 15px;
  }

  section .cards5 .text-cards5 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }


  /* Industries */
  #section41 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section42 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  #section43 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  section .cards6 {
    display: flex;
  }

  section .cards6 .text-cards6 {
    width: 225px;
    height: 300px;
    background-color: rgb(250, 7, 68);
    margin: 10px;
    padding: 15px;
  }

  section .cards6 .text-cards6 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  section .cards7 {
    display: flex;
  }

  section .cards7 .text-cards7 {
    width: 225px;
    height: 300px;
    background-color: rgb(46, 245, 7);
    margin: 10px;
    padding: 15px;
  }

  section .cards7 .text-cards7 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Third section */
  section .cards8 {
    display: flex;
  }

  section .cards8 .text-cards8 {
    width: 225px;
    height: 300px;
    background-color: rgb(173, 4, 252);
    margin: 10px;
    padding: 15px;
  }

  section .cards8 .text-cards8 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fourth section */
  section .cards9 {
    display: flex;
  }

  section .cards9 .text-cards9 {
    width: 225px;
    height: 300px;
    background-color: rgb(248, 5, 187);
    margin: 10px;
    padding: 15px;
  }

  section .cards9 .text-cards9 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Fifth section */
  section .cards10 {
    display: flex;
  }

  section .cards10 .text-cards10 {
    width: 225px;
    height: 300px;
    background-color: rgb(238, 118, 5);
    margin: 10px;
    padding: 15px;
  }

  section .cards10 .text-cards10 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Sixth section */
  section .cards11 {
    display: flex;
  }

  section .cards11 .text-cards11 {
    width: 225px;
    height: 300px;
    background-color: rgb(11, 193, 238);
    margin: 10px;
    padding: 15px;
  }

  section .cards11 .text-cards11 h3 {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }


  #section10 h1 {
    font-size: 30px;
  }

  #section11 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section11 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section11 p {
    width: 100%;
    font-size: 20px;
  }

  #section12 #bigdata img {
    width: 250px;
    height: 250px;
  }

  #section12 h2 {
    width: 100%;
    font-size: 30px;
  }

  #section12 p {
    width: 100%;
    font-size: 20px;
  }

  #section13 h2 {
    width: auto;
    font-size: 30px;
  }

  #section13 p {
    width: auto;
    font-size: 20px;
  }


  /* Aboutus */
  #aboutusblock {
    display: flex;
    flex-direction: column-reverse;
  }

  #aboutuscontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
  }

  #aboutusimage {
    width: 100%;
    padding-right: 45px;
    padding-left: 40px;
    margin-bottom: 15px;
  }

  #aboutusimage img {
    width: 100%;
    height: 200px;
  }

  #aboutuscontent p {
    width: 100%;
    font-size: 8px;
  }

  /* Vision & Mission */
  #visionandmission h1 {
    font-size: 20px;
  }

  #visionandmission h4 {
    font-size: 15px;
  }

  #visionandmission p {
    font-size: 8.5px;
  }

  #visionandmission img {
    width: 100%;
    height: 100px;
  }

  /* Core value */
  #corevalue h1 {
    font-size: 20px;
  }

  #corevalue h3 {
    font-size: 15px;
    text-align: start;
  }

  #corevalue ul li {
    font-size: 12px;
  }

  /* Social Responsibility */
  #socialresponsibilityblock {
    display: flex;
    flex-direction: column;
  }

  #socialresponsibilitycontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
  }

  #socialresponsibilityimg {
    width: 100%;
    padding-left: 45px;
    padding-right: 50px;
    margin-bottom: 15px;
  }

  #socialresponsibilityimg img {
    width: 100%;
    height: 200px;
  }

  #socialresponsibility h1 {
    font-size: 20px;
  }

  #socialresponsibility h2 {
    font-size: 15px;
  }

  #socialresponsibility p {
    font-size: 9px;
  }

  /* Location */
  #location h1 {
    font-size: 30px;
  }

  #location h2 {
    font-size: 16px;
  }

  #location h3 {
    font-size: 13px;
  }

  #location p {
    font-size: 9px;
  }

  #locationbtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #locationbtn #btn1 {
    width: 150px;
    font-size: 25px;
    margin-bottom: 5px;
  }

  #locationbtn #btn2 {
    width: 150px;
    font-size: 25px;
  }

  #locationblock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #locationblock #indialocation {
    width: 350px;
    padding-right: 10px;
    margin-left: 0px;
  }

  #locationblock #indialocation img {
    height: 150px;
  }

  #locationblock #vizaglocation {
    width: 350px;
    padding-right: 10px;
    margin-left: 0px;
  }

  #locationblock #vizaglocation img {
    height: 150px;
  }

  #malaysialocation img {
    height: 150px;
  }

  /* Teams */
  #teams {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  #teamsblock1 {
    margin-right: 10px;
  }

  #teamsblock1 .card {
    width: 225px;
    height: 250px;
    border: none;
  }

  #teamsblock1 .card img {
    width: 100%;
    height: 180px;
  }

  #teamsblock1 .card-body span {
    font-size: 15px;
    font-weight: bold;
  }

  #teamsblock1 .card-body p {
    font-size: 15px;
  }

  #teamsblock2 {
    margin-top: 10px;
    margin-right: 10px;
  }

  #teamsblock2 .card {
    width: 225px;
    height: 250px;
    border: none;
  }

  #teamsblock2 .card img {
    width: 100%;
    height: 180px;
  }

  #teamsblock2 .card-body span {
    font-size: 15px;
    font-weight: bold;
  }

  #teamsblock2 .card-body p {
    font-size: 15px;
  }


  #teamsblock1 .modal-content {
    width: 300px;
    height: 300px;
    left: 0px;
    top: 100px;
    bottom: 10px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock1 .modal-body {
    width: 40%;
  }

  #teamsblock1 .modal-body img {
    width: 100px;
    height: 100px;
    margin-top: 75px;
    margin-left: -7.5px;
  }

  #teamsblock1 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px;
    overflow: scroll;
  }

  #teamsblock1 .modal-title {
    font-size: 11px;
    margin-right: 10px;
  }

  #teamsblock1 .modal-text {
    font-size: 11px;
  }

  #teamsblock1 .modal-header .text {
    font-size: 9px;
    text-align: justify;
  }

  #teamsblock2 .modal-content {
    width: 300px;
    height: 300px;
    left: 0px;
    top: 100px;
    bottom: 10px;
    display: flex;
    flex-direction: row;
  }

  #teamsblock2 .modal-body {
    width: 40%;
  }

  #teamsblock2 .modal-body img {
    width: 100px;
    height: 100px;
    margin-top: 75px;
    margin-left: -7.5px;
  }

  #teamsblock2 .modal-header {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px;
    overflow: scroll;
  }

  #teamsblock2 .modal-title {
    font-size: 11px;
    margin-right: 30px;
  }

  #teamsblock2 .modal-text {
    font-size: 11px;
  }

  #teamsblock2 .modal-header .text {
    font-size: 9px;
    text-align: justify;
  }


  /* Industries */
  /* Financial Services */
  #financialservice h1 {
    font-size: 25px;
  }

  #financialservice h4 {
    font-size: 16px;
  }

  #financialservice p {
    font-size: 9px;
  }

  #financialservice img {
    width: 100%;
    height: 200px;
  }

  #financialserviceblock {
    display: flex;
    flex-direction: column-reverse;
  }

  #financialservicecontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
  }

  #financialserviceimg {
    width: 100%;
    padding-right: 50px;
    padding-left: 45px;
    margin-bottom: 15px;
  }

  /* Government */
  #government h1 {
    font-size: 25px;
  }

  #government h3 {
    font-size: 16px;
  }

  #government p {
    font-size: 9px;
  }

  #government ul li {
    font-size: 9px;
  }

  /* Manufacturing */
  #manufacturing h1 {
    font-size: 25px;
  }

  #manufacturing p {
    font-size: 9px;
  }

  #manufacturing ul li {
    font-size: 9px;
  }

  /* Retail */
  #retail h1 {
    font-size: 25px;
  }

  #retail img {
    width: 100%;
    height: 200px;
  }

  #retail p {
    font-size: 9px;
  }

  #retail ul li {
    font-size: 9px;
    text-align: start;
  }

  #retail #img {
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
  }

  #retail #retailcontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    text-align: justify;
  }

  #retailblock {
    display: flex;
    flex-direction: column;
  }

  /* Telecommunication */
  #telecommunication h1 {
    font-size: 25px;
  }

  #telecommunication h4 {
    font-size: 14px;
  }

  #telecommunication p {
    font-size: 9px;
  }

  #telecommunication ul li {
    font-size: 9px;
    text-align: start;
  }

  #telecommunication img {
    width: 100%;
    height: 200px;
  }

  #telecommunicationblock {
    display: flex;
    flex-direction: column-reverse;
  }

  #telecommunicationcontent {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
  }

  #telecommunicationimg {
    width: 100%;
    padding-right: 45px;
    padding-left: 45px;
  }

  /* Utilities */
  #utilities h1 {
    font-size: 25px;
  }

  #utilities h3 {
    font-size: 20px;
  }

  #utilities p {
    font-size: 9px;
  }

  #utilities ul li {
    font-size: 9px;
  }

  /* Products */
  /* Log-Mode */
  #logmode h1 {
    font-size: 25px;
  }

  #logmode h4 {
    font-size: 15px;
  }

  #logmode h5 {
    font-size: 13px;
  }

  #logmode p {
    font-size: 9px;
  }

  #logmode img {
    width: 220px;
    height: 200px;
  }

  #logmodeblock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
  }

  /* Solutions */
  /* Application Middleware */
  #applicationmiddleware h1 {
    font-size: 25px;
  }

  #applicationmiddleware h3 {
    font-size: 18px;
  }

  #applicationmiddleware p {
    font-size: 9px;
  }

  #applicationmiddleware ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Big Data */
  #bigdata h1 {
    font-size: 25px;
  }

  #bigdata h2 {
    font-size: 20px;
  }

  #bigdata p,
  b {
    font-size: 10px;
  }

  #bigdata ul li {
    font-size: 10px;
  }

  #bigdata img {
    width: 220px;
    height: 200px;
  }

  /* Business Intelligence Solutions */
  #businessintelligence h1 {
    font-size: 25px;
  }

  #businessintelligence h3 {
    font-size: 20px;
  }

  #businessintelligence p {
    font-size: 9px;
    text-align: justify;
  }

  #businessintelligence b {
    font-size: 11px;
  }

  #businessintelligence ul li {
    font-size: 9px;
    text-align: start;
  }

  #businessintelligenceblock1 {
    display: flex;
    flex-direction: column;
  }

  #businessintelligenceblock1 p {
    width: 100%;
  }

  #businessintelligenceblock1 img {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }

  #businessintelligenceblock2 {
    display: flex;
    flex-direction: column;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 #businessintelligencelist {
    width: 50%;
  }

  #businessintelligenceblock2 img {
    width: 100%;
    height: 150px;
  }

  /* Data Management */
  #datamanagement h1 {
    font-size: 25px;
  }

  #datamanagement h3 {
    font-size: 20px;
  }

  #datamanagement p {
    font-size: 9px;
  }

  #datamanagement b {
    font-size: 11px;
  }

  #datamanagement ul li {
    font-size: 9px;
  }

  #datamanagement img {
    width: 100%;
    height: 150px;
  }

  #datamanagementblock {
    display: flex;
    flex-direction: column;
  }

  #datamanagementcontent {
    width: 100%;
  }

  #datamanagementimg {
    width: 100%;
  }

  /* Enterprise Data Warehouse */
  #enterprisedata h1 {
    font-size: 25px;
  }

  #enterprisedata h2 {
    font-size: 20px;
  }

  #enterprisedata p {
    font-size: 9px;
  }

  #enterprisedata b {
    font-size: 11px;
  }

  #enterprisedata ul li {
    font-size: 9px;
    text-align: start;
  }

  #enterprisedata img {
    width: 100%;
    height: 200px;
  }

  #enterprisedatablock {
    display: flex;
    flex-direction: column;
  }

  #enterprisedatacontent {
    width: 100%;
  }

  #enterprisedataimg {
    width: 100%;
  }


  /* Services */
  /* Business Intelligence Services */
  #businessIntelligence h1 {
    font-size: 25px;
  }

  #businessIntelligence h2 {
    font-size: 20px;
  }

  #businessIntelligence h4 {
    font-size: 17px;
  }

  #businessIntelligence p,
  b {
    font-size: 9px;
  }

  #businessIntelligence ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Enterprise Mobility Service */
  #enterprisemobility h1 {
    font-size: 25px;
  }

  #enterprisemobility p {
    font-size: 9px;
  }

  #enterprisemobility ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Mobile Apps */
  #mobileapps h1 {
    font-size: 25px;
  }

  #mobileapps p {
    font-size: 9px;
  }

  #mobileapps ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Testing And QA Services */
  #testingandqa h1 {
    font-size: 25px;
  }

  #testingandqa p {
    font-size: 9px;
  }

  #testingandqa ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Web Application */
  #webapplication h1 {
    font-size: 25px;
  }

  #webapplication p {
    font-size: 9px;
  }

  #webapplication ul li {
    font-size: 9px;
    text-align: start;
  }


  /* Enterprise Mobility */
  /* Business Process */
  #businessprocess h1 {
    font-size: 25px;
  }

  #businessprocess p {
    font-size: 9px;
  }

  /* B2B Mobile */
  #b2bmobile h1 {
    font-size: 25px;
  }

  #b2bmobile h2 {
    font-size: 20px;
  }

  #b2bmobile p {
    font-size: 9px;
  }

  #b2bmobile ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Android App */
  #enterpriseandroid h1 {
    font-size: 25px;
  }

  #enterpriseandroid p {
    font-size: 9px;
  }

  #enterpriseandroid ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Enterprise APP */
  #enterpriseapp h1 {
    font-size: 25px;
  }

  #enterpriseapp h3 {
    font-size: 18px;
  }

  #enterpriseapp p {
    font-size: 9px;
  }

  /* Enterprise IOS App */
  #enterpriseiosapp h1 {
    font-size: 25px;
  }

  #enterpriseiosapp h3 {
    font-size: 17px;
  }

  #enterpriseiosapp p {
    font-size: 9px;
  }

  /* Mobile Business */
  #mobilebusiness h1 {
    font-size: 25px;
  }

  #mobilebusiness p {
    font-size: 9px;
  }

  /* Mobile Apps */
  /* Android App */
  #androidapp h1 {
    font-size: 25px;
  }

  #androidapp p {
    font-size: 9px;
  }

  /* Blackberry App */
  #blackberryapp h1 {
    font-size: 25px;
  }

  #blackberryapp p {
    font-size: 9px;
  }

  /* Iphone App */
  #iphoneapp h1 {
    font-size: 25px;
  }

  #iphoneapp p {
    font-size: 9px;
  }

  /* Windows8 App */
  #windows8app h1 {
    font-size: 25px;
  }

  #windows8app p {
    font-size: 9px;
  }


  /* Testing & QA Services*/
  /* Automated Testing */
  #automatedtesting h1 {
    font-size: 25px;
  }

  #automatedtesting h3 {
    font-size: 18px;
  }

  #automatedtesting p {
    font-size: 9px;
  }

  #automatedtesting ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Manual Testing */
  #manualtesting h1 {
    font-size: 25px;
  }

  #manualtesting h3 {
    font-size: 15px;
  }

  #manualtesting p {
    font-size: 9px;
  }

  #manualtesting ul li {
    font-size: 9px;
    text-align: start;
  }

  /* QTP */
  #qtp h1 {
    font-size: 25px;
  }

  #qtp h3 {
    font-size: 20px;
  }

  #qtp p {
    font-size: 9px;
  }

  #qtp ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Selenium */
  #selenium h1 {
    font-size: 25px;
  }

  #selenium h3 {
    font-size: 20px;
  }

  #selenium p {
    font-size: 9px;
  }

  #selenium ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Test Complete */
  #testcomplete h1 {
    font-size: 25px;
  }

  #testcomplete h3 {
    font-size: 17px;
  }

  #testcomplete p {
    font-size: 9px;
  }

  #testcomplete ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Web Application */
  /* CRM Application */
  #cmrapplication h1 {
    font-size: 25px;
  }

  #cmrapplication p {
    font-size: 9px;
  }

  /* Content Management */
  #contentmanagement h1 {
    font-size: 25px;
  }

  #contentmanagement h3 {
    font-size: 18px;
  }

  #contentmanagement p {
    font-size: 9px;
  }

  #contentmanagement ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Custom Web App */
  #customwebapp h1 {
    font-size: 25px;
  }

  #customwebapp p {
    font-size: 9px;
  }

  #customwebapp ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Inventory Management */
  #inventorymanagement h1 {
    font-size: 25px;
  }

  #inventorymanagement h3 {
    font-size: 20px;
  }

  #inventorymanagement p {
    font-size: 9px;
  }

  #inventorymanagement ul li {
    font-size: 9px;
    text-align: start;
  }

  /* Open Source */
  #opensource h1 {
    font-size: 25px;
  }

  #opensource p {
    font-size: 9px;
  }
}